
import React, {useEffect, useState} from 'react';


import {connect} from 'react-redux';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {createcontact } from '../../api/contact.api'
import {createnewsletter } from '../../api/newsletter.api'

import './nuevohome.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Pnuevohome = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {
	const [loftystate_monto, set_loftystate_monto] = useState()
	const [loftystate_mostrarersultado, set_loftystate_mostrarersultado] = useState()


useEffect(() => {
		const loftyHandleData = async () => {
		}
		loftyHandleData();
	}, []);


  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id64f87521925546452564a70e" >

        <a
          target="_blank"
          className=""
          id="i2a89p"
          href="https://api.whatsapp.com/send/?phone=573183732472"
        >
          
      <img
        className=""
        id="iq5udk"
        src="https://assetsprojects.s3.amazonaws.com/405opfilksrrnz0.png"
        alt="undefined"
      />
      
    <div id="i2j6zu" className="">
    
      <br className="" id="" />
      
    </div>
    
        </a>
        
      <div id="fgfg" className="">
      
      <div id="i99szh" className="">
      
        <a
          target=""
          className=""
          id="i9ymbj"
          href="#"
        >
          
        </a>
        
      <div id="i191yb" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/quienessomos"
        type=""
      >
        <span>Quienes Somos</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/aprende"
        type=""
      >
        <span>Aprende</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#ijf0dn"
        type=""
      >
        <span>Preguntas Frecuentas</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#ijn4q"
        type=""
      >
        <span>Contáctanos</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i90zuz" className="">
      
      <img
        className=""
        id="ic7iul"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik04LjUgMTMuNWwyLjUgMyAzLjUtNC41IDQuNSA2SDVtMTYgMVY1YTIgMiAwIDAgMC0yLTJINWMtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnoiPjwvcGF0aD4KICAgICAgPC9zdmc+"
        alt="undefined"
      />
      
      </div>
      
      <div id="iijpat" className="">
      
        <a
          target=""
          className=""
          id="ity3lq"
          href={`/quienessomos`}
        >
          
        </a>
        
        <a
          target=""
          className="link-navbar "
          id="i7tyyk"
          href={`/`}
        >
          
    <div id="iukmnm" className="">
    <span>INICIO</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-navbar "
          id="ih23wf"
          href="#"
        >
          
    <div id="ie1z1h" className="">
    <span>TRET</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-navbar "
          id="i8md79"
          href="#"
        >
          
    <div id="i3m6sj" className="">
    <span>Sobre Nosotros</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-navbar "
          id="iytr6a"
          href="#"
        >
          
    <div id="itcs5j" className="">
    <span>Noticias</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="inhf99"
          href={`/aprende`}
        >
          
        </a>
        
        <a
          target=""
          className=""
          id="iqazqs"
          href="#ijf0dn"
        >
          
        </a>
        
        <a
          target=""
          className=""
          id="i8jfb8"
          href="#ijn4q"
        >
          
        </a>
        
      </div>
      
      <div id="ikjwac" className="">
      
        <a
          target=""
          className=""
          id="i6vthj"
          href="#"
        >
          
      <img
        className=""
        id="ia321m"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilyoqjlwm.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="idmsmh"
          href="#"
        >
          
      <img
        className=""
        id="ixfatg"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilyoqmjqh.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="icyp1z"
          href="#"
        >
          
      <img
        className=""
        id="isoyat"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilyoqn9j4.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      <div id="sadsa12312" className="">
      
      <div id="ift2d1" className="">
      
      <p className="" id="iegvfq">
        <span>Te ayudamos a manejar el flujo de caja de tu empresa y sacarle el mayor provecho a tu liquidez</span>
      </p>
      
      <div id="ihzv9g" className="">
      
      <img
        className=""
        id="in7jcb"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkj39jqf.png"
        alt="undefined"
      />
      
      <p className="" id="iz16gz">
        <span>Genera más rentabilidad con la liquidez de tu empresa</span>
      </p>
      
      <span className="" id="igsg5g">
        
      </span>
      
      </div>
      
      <div id="i369nf" className="">
      
      <img
        className=""
        id="iaws5f"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkj39jqf.png"
        alt="undefined"
      />
      
      <p className="" id="ilmbvb">
        <span>Reduce costos financieros innecesarios</span>
      </p>
      
      <span className="" id="i3kmuw">
        
      </span>
      
      </div>
      
      <div id="ixroyx" className="">
      
      <img
        className=""
        id="imfvu4"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkj39jqf.png"
        alt="undefined"
      />
      
      <p className="" id="i88g1b">
        <span>Gana velocidad en la toma de decisiones sobre tu liquidez</span>
      </p>
      
      <span className="" id="if6fse">
        
      </span>
      
      </div>
      
      <div id="iy5i6h" className="">
      
      <p className="" id="iwszhf">
        
      </p>
      
      <span className="" id="itsf8p">
        
      </span>
      
      </div>
      
      <div id="iatebk" className="">
      
        <a
          target=""
          className=""
          id="ix9dvo"
          href="#ijn4q"
        >
          
    <div id="i1ko73" className="">
    <span>¡Comienza ahora!</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      <div id="ihna7t" className="">
      
      <p className="" id="i6idor">
        <span>¿Es TRET para mi?</span>
      </p>
      
      <div id="i9dcgp" className="">
      
      <img
        className=""
        id="iw0gbq"
        src="https://assetsprojects.s3.amazonaws.com/405opfilmj2z14e.png"
        alt="undefined"
      />
      
      <div id="iowxor" className="">
      
      <img
        className=""
        id="iyghhc"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkifeeym.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="i97esf"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkb0vspx.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="izyb15" className="">
      
      <div id="izuowi" className="swiper mySwiper ">
      
      <div id="" className="swiper-wrapper ">
      
      <div id="ivnfjk" className="swiper-slide ">
      
      <div id="ietzbl" className="">
      
      <div id="iqkxti" className="gjs-row ">
      
      <div id="ihytpt" className="gjs-cell ">
      
      <div id="iq2pxh" className="">
      
      <img
        className=""
        id="iyrem3"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk12kfjc.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="iawao3" className="gjs-cell ">
      
      <p className="" id="ixnksx">
        <span>¿CÓMO PUEDO SACAR EL MEJOR PROVECHO A  LA LIQUIDEZ DE MI EMPRESA? </span>
      </p>
      
      <p className="" id="i66vqd">
        <span>La liquidez de tu empresa puede que no esté generando mucho en este momento. La razón se encuentra comúnmente escondida en tu flujo de caja.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>¡Sí, ese mismo!, el que te dice cuánto tienes hoy, cuánto vas a recaudar y pagar en éste y los próximos meses.</span>
      <br className="" id="" />
      
      </p>
      
      <p className="" id="irkxey">
        <span>¡Con TRET organizas tus cuentas y rentabilizas tu liquidez! </span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="im6k85" className="swiper-slide ">
      
      <div id="i1bzq4" className="">
      
      <div id="i3jmp4" className="gjs-row ">
      
      <div id="iazjlo" className="gjs-cell ">
      
      <p className="" id="ijmezm">
        <span>¿PODEMOS AYUDARTE EN LA GESTIÓN FINANCIERA DE TU EMPRESA? </span>
      <br className="" id="" />
      
      </p>
      
      <div id="i4a7kt" className="">
      
      <p className="" id="ihocpa">
        <span>Tenemos las habilidades de un buen gerente Financiero en el manejo eficiente de los recursos, detectar riesgos financieros y no menos importante, negociar las mejores condiciones bancarias</span>
      <br className="" id="" />
      
      </p>
      
      <p className="" id="i07jd4">
        <span>¡En TRET te  acompañamos! y te damos esa claridad financiera que necesitas para seguir potencializando tu empresa</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      <div id="itjyzf" className="">
      
        <a
          target="_blank"
          className=""
          id="i60p5e"
          href={`/gestionCFO`}
        >
          
    <div id="i5wclm" className="">
    <span>Cómo TRET me puede ayudar en mi Rol de Financiero</span>
      <br className="" id="" />
      
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      <div id="idzco7" className="gjs-cell ">
      
      <div id="ikfxa5" className="">
      
      <img
        className=""
        id="io6oqz"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkfv1u1c.jpg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i1vy5h" className="swiper-slide ">
      
      <div id="ic8shi" className="">
      
      <div id="idcjx3" className="gjs-row ">
      
      <div id="imrwbu" className="gjs-cell ">
      
      <img
        className=""
        id="i33pmj"
        src="https://assetsprojects.s3.amazonaws.com/405opfilklpr4en.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="i9khdf" className="gjs-cell ">
      
      <p className="" id="ifx4jx">
        <span>¿Demasiado ocupado para ver todas las oportunidades financieras?</span>
      </p>
      
      <p className="" id="izi2gy">
        <span>Sabemos lo retador que es mantener tu negocio funcionando y queremos ayudarte a reducir la carga. </span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Por esto, a partir de una poca información financiera, TRET te construye y analiza tu flujo de caja, monitorea los productos financieros disponibles y encuentra las mejores respuestas para que puedas rentabilizar tu liquidez.</span>
      <br className="" id="" />
      
      </p>
      
      <p className="" id="ifo4vp">
        <span>¡Recuerda que este flujo de caja es tuyo y te sirve para planear el futuro financiero de tu negocio!</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i2jzug" className="swiper-slide ">
      
      <div id="iehobh" className="">
      
      <div id="" className="gjs-row ">
      
      <div id="iza2lu" className="gjs-cell ">
      
      <p className="" id="i0ywmd">
        <span>¿Quieres mantener las cosas simples?</span>
      <br className="" id="" />
      
      </p>
      
      <p className="" id="idp7be">
        <span>Los softwares normalmente requieren horas de tu tiempo y de tu equipo para realizar su instalación y mantenimiento, pero realmente no te dan respuestas claras sobre lo que debes hacer.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>TRET te da las respuestas que estas buscando para tener un manejo óptimo de tu flujo de caja, lo que te permitirá tener más tiempo para las cosas importantes, como enfocarte en tú negocio, tu familia o tus hobbies.</span>
      <br className="" id="" />
      
      </p>
      
      <p className="" id="i2y5vj">
        <span>¡TRET simplifica tu vida!</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      <div id="i2bxag" className="gjs-cell ">
      
      <img
        className=""
        id="iuh9xq"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkcxaspf.jpg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="" className="swiper-button-prev ">
      
      </div>
      
      <div id="i2tvz8" className="swiper-button-next ">
      
      </div>
      
      <div id="" className="swiper-pagination ">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i0s89w" className="">
      
      <div id="ix9dnu" className="">
      
      <div id="ikca1r" className="">
      
      <p className="" id="iy6aph">
        <span>GANA MÁS CON TRET</span>
      <br className="" id="" />
      
      </p>
      
      <p className="" id="izxise">
        <span>Entendemos lo que se necesitas:</span>
      </p>
      
      <img
        className=""
        id="i46oep"
        src="https://assetsprojects.s3.amazonaws.com/405opfillb1nswb.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="ia62sr"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkfvo0vh.png"
        alt="undefined"
      />
      
      <div id="ikwh2i" className="">
      
      <div
        id="i23teu"
        className=""
        data-aos="fade-up"
        data-aos-duration="1500"
      >
      
      <div id="its6nc" className="">
      
      <p className="" id="it45rn">
        <span>¿Cuánto de tu liquidez puedes invertir?</span>
      </p>
      
      <div id="is4twv" className="">
      
        <div id="undefined" className="inputContainer ">
          
      <div id="" className="">
      <span>Sample</span>
      </div>
      
        <input
          name=""
          type="text"
          placeholder="Ingresa el monto"
          className="formInput "
          id=""
          onChange={(e) => {
            set_loftystate_monto(e.target.value);
          }}
          value={loftystate_monto}
        />
        
        </div>
      
      <button
        type=""
        name=""
        id=""
        className="standard_button "
        
      >
      
      <div id="ir0jgl" className="">
      <span>SIMULAR</span>
      </div>
      
      </button>
      
      </div>
      
        <a
          target=""
          className=""
          id="im2yrh"
          href="#"
        >
          
        </a>
        
      <p className="" id="il4rcg">
        <span>              ¿Nos sabes cuánto de tu liquidez puedes invertir?</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      <div
        id="ia8hlv"
        className=""
        data-aos="fade-up"
        data-aos-duration="1500"
      >
      
      <div id="i2myo4" className="">
      
      <p className="" id="i3n8wx">
        <span>¿Cuánto hay en tus cuentas hoy?</span>
      </p>
      
      <div id="iu10pf" className="">
      
        <div id="undefined" className="inputContainer ">
          
      <input
        name=""
        type="text"
        placeholder="Ingrese el monto"
        className="formInput "
        id=""
      />
      
        </div>
      
      </div>
      
      <p className="" id="i9ipv4">
        <span>¿Cuánto es el valor de tus ventas mensuales?</span>
      </p>
      
      <div id="ipb61c" className="">
      
        <div id="undefined" className="inputContainer ">
          
      <input
        name=""
        type="text"
        placeholder="Ingrese el monto"
        className="formInput "
        id=""
      />
      
        </div>
      
      </div>
      
      <p className="" id="inkoxg">
        <span>¿Cuánto es el valor de tus pagos mensuales?</span>
      </p>
      
      <div id="iuqtx7" className="">
      
        <div id="undefined" className="inputContainer ">
          
      <input
        name=""
        type="text"
        placeholder="Ingrese el monto"
        className="formInput "
        id=""
      />
      
        </div>
      
      </div>
      
      <div id="iz7j17" className="">
      
      <button
        type=""
        name=""
        id=""
        className="standard_button "
        
      >
      
      <div id="itlx1m" className="">
      <span>SIMULAR</span>
      </div>
      
      </button>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i0v23u" className="">
      
      <img
        className=""
        id="ic5gzq"
        src="https://assetsprojects.s3.amazonaws.com/405opfilku9k27b.png"
        alt="undefined"
      />
      
        <a
          target=""
          className=""
          id="ij64bv"
          href={`/gestionactiva2`}
        >
          
      <p className="" id="i4gupk">
        <span>Aprende más sobre los Beneficios de hacer una gestión activa</span>
      </p>
      
        </a>
        
      </div>
      
      </div>
      
      <div id="isyxuq" className="">
      
      <div id="i42563" className="">
      
      <p className="" id="i038gn">
        
      <b className="" id="iovgtm">
        <span>LO QUE PUEDE SIGNIFICAR UNA MEJOR GESTIÓN DE TU DINERO</span>
      </b>
      
      </p>
      
      </div>
      
      <div id="il0odp" className="">
      
      <div
        id="ivmt4l"
        className=""
        data-aos="fade-down"
        data-aos-duration="undefined"
      >
      
      <div id="idl6zv" className="">
      
      <p className="" id="ihhgjv">
        <span>Social</span>
      </p>
      
      <img
        className=""
        id="i2ejqs"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkttxi7e.jpg"
        alt="undefined"
      />
      
      <p className="" id="ii8n2h">
        <span>Donación a caridad favoritdad de $ 6,000,000 de pesos colombianos</span>
      </p>
      
      </div>
      
      </div>
      
      <div
        id="iui9eh"
        className=""
        data-aos="fade-down"
        data-aos-duration="undefined"
      >
      
      <div id="i39mnb" className="">
      
      <p className="" id="i44578">
        <span>Empresarial</span>
      </p>
      
      <img
        className=""
        id="ior55f"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkgz2wyr.png"
        alt="undefined"
      />
      
      <p className="" id="iq01ck">
        <span>Capacitación a 20 empleados</span>
      </p>
      
      </div>
      
      </div>
      
      <div
        id="ik4j6e"
        className=""
        data-aos="flip-down"
        data-aos-duration="undefined"
      >
      
      <div id="iey6gf" className="">
      
      <p className="" id="i1gp21">
        <span>Familiar</span>
      </p>
      
      <img
        className=""
        id="iqbth8"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkttycjo.jpg"
        alt="undefined"
      />
      
      <p className="" id="iac3g8">
        <span>- 2 mensualidades del colegio de tu hij@.</span>
      <br className="" id="" />
      <span>- 1 Semestres de universidad de tu hij@.</span>
      <br className="" id="" />
      <span>- 1 meses de educación de tu hij@ en el exterior.</span>
      </p>
      
      </div>
      
      </div>
      
      <div
        id="idr2c4"
        className=""
        data-aos="fade-down"
        data-aos-duration="undefined"
      >
      
      <div id="i8xeyw" className="">
      
      <p className="" id="iw65fh">
        <span>Personal</span>
      </p>
      
      <img
        className=""
        id="i56tlk"
        src="https://assetsprojects.s3.amazonaws.com/405opfilku9qhs2.jpg"
        alt="undefined"
      />
      
      <p className="" id="ird0ml">
        <span>3 noches en Tuquía o Dubai</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i2d52f" className="">
      
      <button
        type=""
        name=""
        id=""
        className="standard_button "
        
      >
      
      <div id="izlrtu" className="">
      <span>Realizar otra simulación</span>
      </div>
      
      </button>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iedcax" className="">
      
      <div id="ixlnbd" className="">
      
      <div id="iiocdi" className="gjs-row ">
      
      <div id="iidk1h" className="gjs-cell ">
      
      <p className="" id="iuzzss">
        <span>¿CÓMO PUEDO SACAR EL MEJOR PROVECHO A  LA LIQUIDEZ DE MI EMPRESA? </span>
      </p>
      
      <div id="ikxt8n" className="">
      
      <img
        className=""
        id="i5hnm7"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk12kfjc.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="iojt1n">
        <span>La liquidez de tu empresa puede que no esté generando mucho en este momento. La razón se encuentra comúnmente escondida en tu flujo de caja de “palitos”.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>¡Sí, ese mismo!, el que te dice cuánto tienes hoy, cuánto vas a recaudar y pagar en éste y los próximos meses.</span>
      <br className="" id="" />
      
      </p>
      
      <p className="" id="i3k1ps">
        <span>¡Con TRET organizas tus cuentas y rentabilizas tu liquidez! </span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="imbtpw" className="">
      
      <div id="ip4y7i" className="gjs-row ">
      
      <div id="itb7l6" className="gjs-cell ">
      
      <p className="" id="i5qnlj">
        <span>¿PODEMOS AYUDARTE EN LA GESTIÓN FINANCIERA DE TU EMPRESA? </span>
      <br className="" id="" />
      
      </p>
      
      <div id="inp01f" className="">
      
      <p className="" id="i7y3wk">
        <span>Tenemos las habilidades de un buen gerente Financiero en el manejo eficiente de los recursos, detectar riesgos financieros y no menos importante, negociar las mejores condiciones bancarias</span>
      <br className="" id="" />
      
      </p>
      
      <p className="" id="ii3exs">
        <span>¡En TRET te  acompañamos! y te damos esa claridad financiera que necesitas para seguir potencializando tu empresa</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      <div id="i593co" className="">
      
        <a
          target=""
          className=""
          id="iw9lml"
          href="#"
        >
          
    <div id="ickahx" className="">
    <span>Cómo TRET me puede ayudar en mi Rol de Financiero</span>
      <br className="" id="" />
      
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      <div id="iqz0iq" className="gjs-cell ">
      
      <div id="icn7ep" className="">
      
      <img
        className=""
        id="ii182q"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkfv1u1c.jpg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="il3mwx" className="">
      
      <div id="ibg3ql" className="gjs-row ">
      
      <div id="i86eug" className="gjs-cell ">
      
      <p className="" id="i2vtik">
        <span>¿Demasiado ocupado para ver todas las oportunidades financieras?</span>
      </p>
      
      <img
        className=""
        id="ipu864"
        src="https://assetsprojects.s3.amazonaws.com/405opfilklpr4en.png"
        alt="undefined"
      />
      
      <p className="" id="ihl6cv">
        <span>Sabemos lo retador que es mantener tu negocio funcionando y queremos ayudarte a reducir la carga. </span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Por esto, a partir de una poca información financiera, Ttool te construye y analiza tu flujo de caja, monitorea los productos financieros disponibles y encuentra las mejores respuestas para que puedas rentabilizar tu liquidez.</span>
      <br className="" id="" />
      
      </p>
      
      <p className="" id="icisee">
        <span>¡Recuerda que este flujo de caja es tuyo y te sirve para planear el futuro financiero de tu negocio!</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iskzsk" className="">
      
      <div id="itdwt8" className="gjs-row ">
      
      <div id="ingl1l" className="gjs-cell ">
      
      <p className="" id="if0mei">
        <span>¿QUIERES MANTENER LAS COSAS SIMPLES? </span>
      <br className="" id="" />
      
      </p>
      
      <p className="" id="iqkb1c">
        <span>Los softwares normalmente requieren horas de tu tiempo y de tu equipo para realizar su instalación y mantenimiento, pero realmente no te dan respuestas claras sobre lo que debes hacer.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>TRET te da las respuestas que estas buscando para tener un manejo óptimo de tu flujo de caja y liquidez que te permite tener más tiempo para las cosas importantes, ya sea enfocarte en tú negocio, tu familia o tus hobbies.</span>
      <br className="" id="" />
      
      </p>
      
      <p className="" id="iirnle">
        <span>¡TRET simplifica tu vida!</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      <div id="if39bb" className="gjs-cell ">
      
      <img
        className=""
        id="i9244h"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkcxaspf.jpg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iy5d4i" className="">
      
      <div id="iodktd" className="">
      
      <div id="id1a5g" className="">
      
      <p className="" id="ihhfe5">
        <span>¡Optimiza tu flujo de caja! Suscríbete a nuestro newsletter para recibir consejos financieros clave. Únete hoy mismo</span>
      </p>
      
      </div>
      
      <div id="i0rh0d" className="">
      
        <div className="" id="ivcjhg">
          
      <form
        id="if6fsv"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					createnewsletter({
						email: e.target.email.value,
					});
				} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="ipzioj" className="">
      
      <label id="i73unh" className="">
      <span>Correo</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="email"
        type="text"
        placeholder="Correo"
        className=""
        id="ij34ch"
      />
      
      </div>
      
      <div id="ilwqxi" className="">
      
      <button
        type="submit"
        name=""
        id="iosmfq"
        className=""
        
      >
      <span>Suscribirme</span>
      </button>
      
      </div>
      
      </form>
      
        </div>
        
      </div>
      
      </div>
      
      </div>
      
      <div id="ijf0dn" className="">
      
      <p className="" id="idoznl">
        <span>Preguntas Frecuentes</span>
      </p>
      
      <div id="il1nb6" className="">
      
      <div id="ioj82f" className="gjs-row ">
      
      <div id="ia6ouh" className="gjs-cell ">
      
      <div
        id="i6ipsq"
        className=""
        data-aos="zoom-out-left"
        data-aos-duration="1000"
      >
      
      <div id="iwa5hx" className="">
      
      <div id="iqxc3b" className="">
      
      <p className="" id="icev5f">
        <span>¿Aumentar la rentabilidad implicaría mayores riesgos?</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      <div id="igkdmr" className="">
      
      <p className="" id="il1xcp">
        <span>Nuestras sugerencias se basan en los productos financieros tradicionales ofrecidos por bancos AAA para optimizar la gestión de la liquidez. Esto se fundamenta en la premisa clave de que la liquidez de la empresa debe ser utilizada en su capital de trabajo.</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iqg3k9" className="gjs-cell ">
      
      <div
        id="i108fe"
        className=""
        data-aos="zoom-out-left"
        data-aos-duration="1000"
      >
      
      <div id="isv7lf" className="">
      
      <div id="iihwqk" className="">
      
      <p className="" id="ijegj6">
        <span>¿Para maximizar mi rentabilidad, debo hacer cambio de mis bancos?</span>
      </p>
      
      </div>
      
      <div id="i97jxf" className="">
      
      <p className="" id="iub2si">
        <span>No necesariamente. Sin embargo, nuestra experiencia nos ha demostrado que concentrar todas las finanzas en un solo banco puede resultar en ineficiencias en costos financieros, como tasas de créditos y comisiones en pagos a terceros o nómina, así como en la rentabilidad ofrecida en cuentas de ahorro, CDT, entre otros. Por tanto, diversificar en productos y entidades bancarias termina siendo una estrategia más efectiva para la rentabilidad de la empresa</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ij43gl" className="gjs-cell ">
      
      <div
        id="ij6n8v"
        className=""
        data-aos="zoom-out-left"
        data-aos-duration="1000"
      >
      
      <div id="iljxoq" className="">
      
      <div id="ifl058" className="">
      
      <p className="" id="i6okdf">
        <span>¿Si no tengo Excedentes de caja o hago uso créditos bancarios puedo optimizar mi flujo de caja?</span>
      </p>
      
      </div>
      
      <div id="iynmlf" className="">
      
      <p className="" id="irny2r">
        <span>Las empresas que recurren al crédito para cubrir su faltante de liquidez, a menudo concluyen que no disponen de liquidez para generar una rentabilidad adicional. Sin embargo, tras un análisis inicial, en muchos casos, nos damos cuenta de que existe una liquidez circulante que podría ser optimizar de manera efectiva. Esto permitiría reducir significativamente los costos financieros,y generar mayores ingresos para la empresa</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i0byfs" className="gjs-cell ">
      
      <div
        id="izlyxp"
        className=""
        data-aos="zoom-out-left"
        data-aos-duration="1000"
      >
      
      <div id="it0upr" className="">
      
      <div id="idrc8g" className="">
      
      <p className="" id="ixzmcg">
        <span>¿Por qué pagar Asesoría Financiera no es lo mismo que tener un Contador?</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      <div id="ithzbf" className="">
      
      <p className="" id="imxsgd">
        <span>Un Contador es el profesional encargado de gestionar la contabilidad de la empresa, registrando los movimientos contables, elaborando estados financieros e informes fiscales, todo basado en la información proporcionada por la empresa. Por otro lado, un Asesor Financiero se dedica a optimizar los recursos monetarios de la empresa. Utiliza diversos parámetros para analizar toda la información financiera de la empresa y comprender su situación actual y proyecciones futuras. De esta manera, puede ofrecer recomendaciones estratégicas y ayudar a tomar decisiones oportunas, precisas y confiables en relación con la realidad financiera del negocio.</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iagkdc" className="gjs-cell ">
      
      <div
        id="ia3xxr"
        className=""
        data-aos="zoom-out-left"
        data-aos-duration="1000"
      >
      
      <div id="ik8ywd" className="">
      
      <div id="ingim6" className="">
      
      <p className="" id="i5jqld">
        <span>¿TRET es una empresa que se dedica a administrar recursos?</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      <div id="i2unee" className="">
      
      <p className="" id="i2zh1q">
        <span>TRET es una empresa que actúa como aliado estratégico para otras compañías, brindando información financiera relevante y valiosa. Nuestra misión es empoderarte para que puedas tomar las mejores decisiones para tu empresa sin perder autonomía en la administración de tus recursos. Nos enfocamos en proporcionarte datos precisos y análisis detallados para que puedas optimizar tus operaciones y alcanzar tus objetivos financieros con confianza. Nuestra colaboración te permitirá tomar decisiones informadas y estratégicas para el crecimiento y éxito de tu negocio.</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ill7mc" className="gjs-cell ">
      
      <div
        id="ibp7o3"
        className=""
        data-aos="zoom-out-left"
        data-aos-duration="1000"
      >
      
      <div id="ikgsef" className="">
      
      <div id="isq4t3" className="">
      
      <p className="" id="i3hmy1">
        <span>¿cómo se cuida la información financiera de mi empresa?</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      <div id="im6qzf" className="">
      
      <p className="" id="i30tzk">
        <span>La información de tu empresa se tratará de acuerdo a nuestra Política de Tratamiento de Datos, según la Ley 1581 de 2012, y que se encuentra detallada en nuestro apartado de términos y condiciones. Te invitamos a consultarla. </span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ijn4q" className="">
      
      <p className="" id="in7cyv">
        <span>¿Quieres conocer cómo puedes conseguir que tus</span>
      <br className="" id="" />
      <span>recursos lleguen al máximo de su potencial? </span>
      </p>
      
        <div className="" id="icbglp">
          
      <form
        id="is002f"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					createcontact({
						nombre: e.target.nombre.value,
						empresa: e.target.empresa.value,
						telefono: e.target.telefono.value,
						correo: e.target.correo.value,
					});
					alert("Se envió exitosamente la información");
		} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="ij9nul" className="">
      
      <label id="ihdlid" className="">
      <span>Nombre:</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="nombre"
        type="text"
        placeholder=""
        className=""
        id="inzr6s"
      />
      
      </div>
      
      <div id="i62lny" className="">
      
      <label id="imp4u3" className="">
      <span>Empresa:</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="empresa"
        type="text"
        placeholder=""
        className=""
        id="iiic3j"
      />
      
      </div>
      
      <div id="im6ilb" className="">
      
      <label id="ijj15h" className="">
      <span>Teléfono:</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="telefono"
        type="text"
        placeholder=""
        className=""
        id="ihkf52"
      />
      
      </div>
      
      <div id="irc4ec" className="">
      
      <label id="idzwl7" className="">
      <span>E-Mail:</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="correo"
        type="text"
        placeholder=""
        className=""
        id="i120ad"
      />
      
      </div>
      
      <div id="iqdav3" className="">
      
      <button
        type="submit"
        name=""
        id="i47zps"
        className=""
        
      >
      <span>Enviar</span>
      </button>
      
      </div>
      
      </form>
      
        </div>
        
      </div>
      
      <div id="i5of" className="">
      
      <div id="ifd8zi" className="">
      
      <img
        className=""
        id="i43e3j"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkr5bykb.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="i8lmjf" className="">
      
      <div id="iqpkke" className="">
      
      <p className="" id="iy8adh">
        <span>Dirección</span>
      </p>
      
      <p className="" id="iiauur">
        <span>Bogotá, Colombia</span>
      </p>
      
        <a
          target="_blank"
          className=""
          id="igtu6h"
          href="/terminosycondiciones"
        >
          
    <div id="iyptos" className="">
    <span>Términos y Condiciones</span>
    </div>
    
        </a>
        
      </div>
      
      <div id="imh7h6" className="">
      
      <p className="" id="ijoril">
        <span>Correo de contacto</span>
      </p>
      
      <p className="" id="ik2c1u">
        <span>info@tret.com.co</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iw4n9w" className="">
      
      <div id="ianz3m" className="">
      
      </div>
      
      <div id="iufalf" className="">
      
      <p className="" id="ij1xap">
        <span>Coyright©2023 TRET - Todos los derechos reservados.</span>
      </p>
      
      <div id="ielirh" className="">
      
        <a
          target=""
          className=""
          id="iq0c3q"
          href="https://www.linkedin.com/company/tretool/"
        >
          
      <img
        className=""
        id="i1414e"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk14i6xh.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pnuevohome);
  
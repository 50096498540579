
import React from 'react';


import {connect} from 'react-redux';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';


import './khristian1.scss';






const Pkhristian1 = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {




  
  
  
  

  
  return (
    <div id="id64cbc52592554645256452ae" >

      <div id="i55h" className="">
      
      <div id="id6iic" className="">
      
        <a
          target=""
          className=""
          id="ikuk3f"
          href={`/`}
        >
          
      <img
        className=""
        id="i9pt4f"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkr1t22z.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="izuf7m" className="">
      
        <a
          target=""
          className=""
          id="i3mkrh"
          href={`/quienessomos`}
        >
          
      <p className="" id="ilb64w">
        <span>Quiénes Somos</span>
      </p>
      
        </a>
        
        <a
          target=""
          className=""
          id="ibhmfa"
          href={`/aprende`}
        >
          
      <p className="" id="il1q3h">
        <span>Aprende</span>
      </p>
      
        </a>
        
        <a
          target=""
          className=""
          id="i7q4zk"
          href="/#ijf0dn"
        >
          
      <p className="" id="it6im4">
        <span>Preguntas</span>
      <br className="" id="" />
      <span>Frecuentes</span>
      </p>
      
        </a>
        
        <a
          target=""
          className=""
          id="ilbw8k"
          href="/#ijn4q"
        >
          
      <p className="" id="ixd91v">
        <span>Contáctanos</span>
      </p>
      
        </a>
        
      </div>
      
      </div>
      
      <div id="icv7c" className="">
      
      </div>
      
      <div id="ifxad2" className="">
      
      <div id="ijzglu" className="">
      
      <p className="" id="isdsyu">
        <span>¿En qué consiste una gestión activa del flujo de caja en la empresa?</span>
      </p>
      
      <div id="icj91z" className="">
      
      <p className="" id="ibmtil">
        <span>Para llevar a cabo una gestión activa eficiente de la liquidez de tu empresa, hay dos aspectos clave a considerar:</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      <b className="" id="ihsf9y">
        <span>1.</span>
      </b>
      <span>  Tener información clara y completa sobre tu situación financiera actual y las opciones que ofrece el mercado financiero. Es fundamental estar bien informado para tomar decisiones informadas.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      <b className="" id="">
        <span>2. </span>
      </b>
      <span> Llevar </span>
      <span className="" id="iz0ew9">
        <span>a cabo acciones</span>
      </span>
      
      <span className="" id="i4cou6">
        <span>concretas</span>
      </span>
      <span> basadas en la información disponible. La ejecución es crucial para que cualquier negocio progrese y alcance sus metas financieras.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Para poder hacer una gestión activa eficiente de nuestros recursos, </span>
      <u className="" id="">
        
      <b className="" id="i9wnay">
        <span>es importante tener en cuenta cual es la información exacta que necesitamos:</span>
      <br className="" id="" />
      
      </b>
      
      </u>
      
      <br className="" id="" />
      
      <p className="MsoListParagraphCxSpFirst " id="i4cneg">
        
      <b className="" id="i44ifv">
        <span>     1</span>
      </b>
      
      <span className="" id="iirik3">
        
      </span>
      
      <b className="gjs-selected " id="i3tghk">
        
      </b>
      
      <span className="" id="igq2ff">
        <span>Construyendo y actualizando permanentemente
el flujo de caja de la empresa que nos permite tomar decisiones más rápidas en
tiempo real, pues es la clave fundamental para determinar la mejor estrategia
para nuestra situación actual de liquidez y aprovechar las oportunidades que se
nos presentan.</span>
      </span>
      
      </p>
      
      <p className="MsoListParagraphCxSpFirst " id="ixrnwe">
        
    <div id="ic63m4" className="">
    
    </div>
    
      </p>
      
      <p className="MsoListParagraphCxSpMiddle " id="i1ujid">
        
      <span className="" id="">
        
      <span className="" id="i1i7hs">
        
      <span className="" id="ir3tek">
        
    <div id="" className="">
    
      <span className="" id="irnmo3">
        
      <b className="" id="">
        
      </b>
      
      </span>
      
    </div>
    
      </span>
      
      </span>
      
      </span>
      
      <b className="" id="iw0p8i">
        
      </b>
      
      <span className="" id="ijc41c">
        <span>2.  </span>
      </span>
      
      <span className="" id="ihpv0j">
        <span>Seguimiento en tiempo real a los productos
financieros, generación de alarmas y la mejor estrategia entre los diferentes
productos que maximice nuestra rentabilidad.</span>
      </span>
      
      </p>
      
      <p className="MsoListParagraphCxSpMiddle gjs-selected " id="ieal3y">
        
    <div id="iof7ez" className="">
    
    </div>
    
      </p>
      
      <p className="MsoListParagraphCxSpLast " id="">
        
    <div id="" className="">
    
    </div>
    
      </p>
      
      <p className="MsoNormal " id="">
        <span>En </span>
      <b className="" id="">
        <span>TRET</span>
      </b>
      <span> te ayudamos con todo lo anterior y sabemos cómo
negociar con las entidades financieras, apoyando a nuestros clientes para
lograr mejores condiciones. </span>
    <div id="" className="">
    
    </div>
    
      </p>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i5of" className="">
      
      <div id="i8lmjf" className="">
      
      <div id="im4a28" className="">
      
      <img
        className=""
        id="iteqmg"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkr5bykb.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="iqpkke" className="">
      
      <p className="" id="iy8adh">
        <span>Dirección</span>
      </p>
      
      <p className="" id="iiauur">
        <span>Bogota, Colombia</span>
      </p>
      
      </div>
      
      <div id="imh7h6" className="">
      
      <p className="" id="ijoril">
        <span>Correo de contacto</span>
      </p>
      
      <p className="" id="ik2c1u">
        <span>info@tret.com.co</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iw4n9w" className="">
      
      <div id="ianz3m" className="">
      
      </div>
      
      <div id="iufalf" className="">
      
      <p className="" id="ij1xap">
        <span>Coyright©2023 TRET - Todos los derechos reservados.</span>
      </p>
      
      <div id="ielirh" className="">
      
      <img
        className=""
        id="ii3z3k"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk14hfve.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="i1414e"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk14i6xh.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="it3ooa"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk14iokp.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pkhristian1);
  
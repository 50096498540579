
import React from 'react';


import {connect} from 'react-redux';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';


import './terminosycondiciones.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Pterminosycondiciones = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {




  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id64c16e059255464525642487" >

      <div id="i55h" className="">
      
      <div id="id6iic" className="">
      
        <a
          target=""
          className=""
          id="ikuk3f"
          href="/"
        >
          
      <img
        className=""
        id="i9pt4f"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkr1t22z.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/quienessomos"
        type=""
      >
        <span>Quienes Somos</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/aprende"
        type=""
      >
        <span>Aprende</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/#ijf0dn"
        type=""
      >
        <span>Preguntas Frecuentes</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/#ijn4q"
        type=""
      >
        <span>Contáctanos</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      <div id="izuf7m" className="">
      
        <a
          target=""
          className=""
          id="i3mkrh"
          href={`/quienessomos`}
        >
          
      <p className="" id="ilb64w">
        <span>Quiénes Somos</span>
      </p>
      
        </a>
        
        <a
          target=""
          className=""
          id="ibhmfa"
          href={`/aprende`}
        >
          
      <p className="" id="il1q3h">
        <span>Aprende</span>
      </p>
      
        </a>
        
        <a
          target=""
          className=""
          id="i7q4zk"
          href="/#ijf0dn"
        >
          
      <p className="" id="it6im4">
        <span>Preguntas Frecuentes</span>
      </p>
      
        </a>
        
        <a
          target=""
          className=""
          id="ilbw8k"
          href="/#ijn4q"
        >
          
      <p className="" id="ixd91v">
        <span>Contáctanos</span>
      </p>
      
        </a>
        
      </div>
      
      </div>
      
      <div id="icv7c" className="">
      
      </div>
      
      <div id="ifxad2" className="">
      
      <div id="ijzglu" className="">
      
      <p className="" id="isdsyu">
        <span>Términos y Condiciones</span>
      </p>
      
      <img
        className=""
        id="i3cydu"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik04LjUgMTMuNWwyLjUgMyAzLjUtNC41IDQuNSA2SDVtMTYgMVY1YTIgMiAwIDAgMC0yLTJINWMtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnoiPjwvcGF0aD4KICAgICAgPC9zdmc+"
        alt="undefined"
      />
      
      <p className="" id="iuie77">
        
      <br className="" id="ijhokm-2" />
      <span>Política de Tratamiento de Datos Personales de TRETOOL SAS</span>
      </p>
      
      <p className="" id="ibmtil">
        
      <b className="" id="">
        <span>1. Introducción</span>
      </b>
      
      <br className="" id="" />
      <span>En TRET estamos comprometidos con la protección y privacidad de los datos personales de nuestros usuarios y clientes. Esta política establece cómo recopilamos, almacenamos, utilizamos, compartimos y protegemos la información personal de nuestros usuarios de acuerdo con la legislación colombiana de protección de datos.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Es requisito necesario para el uso de los beneficios de TRET, el usuario lea y acepte los siguientes Términos y Condiciones que a continuación se redactan. En caso de seguir navegando en nuestra página web acepta los presente Términos y Condiciones y podrá acceder a los servicios ofrecidos a través TRET. El uso del portal, así como registrarse como usuario en cualquiera de sus modalidades presentes o futuras implicará que usted ha leído y aceptado los Términos y Condiciones en el presente documento.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>LEA ESTAS CONDICIONES DETENIDAMENTE ANTES DE ACCEDER O USAR EL PORTAL DE TRET, CON EL PROPÓSITO DE ASEGURARSE DE QUE USTED ENTIENDE CADA DISPOSICIÓN, LAS CUALES SE DESCRIBEN A CONTINUACIÓN. DE NO SER ASÍ, LASTIMOSAMENTE TE PEDIMOS QUE NO ACCEDAS A NUESTROS CANALES NI SUMINISTRES TU INFORMACIÓN PERSONAL.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      <b className="" id="">
        <span>2. Alcance</span>
      </b>
      
      <br className="" id="" />
      <span>Esta política aplica para toda la información personal registrada en las bases de datos de TRET quien actúa en calidad de responsable del tratamiento de los datos personales.</span>
      <br className="" id="" />
      <span>TRET podrá modificar los presentes Términos y Condiciones relativo a los Servicios cuando los considere convenientes, con un simple aviso por cualquier medio a los usuarios. Las modificaciones serán efectivas después de la comunicación por parte de TRET bajo cualquier modalidad de los Términos y Condiciones sobre el servicio aplicable. Su posterior acceso o uso continuado de los Servicios después de dicha comunicación constituye un consentimiento tácito y vinculante hacia los presentes Términos y Condiciones y sus modificaciones.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      <b className="" id="">
        <span>3. Responsable del Tratamiento</span>
      </b>
      
      <br className="" id="" />
      <span>TRETOOL S.A.S., NIT 901737830-9, Contacto: khristianmadrid@gmail.com, lemurciag@gmail.com</span>
      <br className="" id="" />
      
      <b className="" id="">
        
      <br className="" id="" />
      <span>4. Obligación</span>
      </b>
      
      <br className="" id="" />
      <span>Esta política es de obligatorio y estricto cumplimiento para TRET.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      <b className="" id="">
        <span>5. Definiciones</span>
      </b>
      
      <br className="" id="" />
      <span>Autorización: Es el consentimiento que da cualquier persona para que las empresas o personas responsables del tratamiento de la información, puedan utilizar sus datos personales.</span>
      <br className="" id="" />
      <span>Base de datos: Conjunto organizado de datos personales que son objeto de tratamiento.</span>
      <br className="" id="" />
      <span>Datos Personales: Se trata de cualquier información vinculada o que pueda asociarse a una persona determinada, como su nombre o número de identificación, o que puedan hacerla determinable, como sus rasgos físicos.</span>
      <br className="" id="" />
      <span>Habeas Data: Derecho que otorga la facultad al titular de datos personales de exigir de las administradoras de esos datos el acceso, inclusión, exclusión, corrección, adición, actualización y certificación de los datos, así como la limitación en las posibilidades de su divulgación, publicación o cesión, de conformidad con los principios que regulan el proceso de administración de datos personales.</span>
      <br className="" id="" />
      <span>Encargado del tratamiento: Es la persona natural o jurídica que realiza el tratamiento de datos personales, a partir de una delegación que le hace el responsable, recibiendo instrucciones acerca de la forma en la que deberán ser administrados los datos.</span>
      <br className="" id="" />
      <span>Responsable del tratamiento: Es la persona natural o jurídica, pública o privada, que decide sobre la finalidad de las bases de datos y/o el tratamiento de los mismos.</span>
      <br className="" id="" />
      <span>Titular: Es la persona natural cuyos datos personales son objeto de tratamiento.</span>
      <br className="" id="" />
      <span>Tratamiento: Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión.</span>
      <br className="" id="" />
      <span>Finalidad: se refiere a los motivos, usos y objetivos que piensa aplicar el responsable o encargado del tratamiento, respecto a los datos personales recabados.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      <b className="" id="">
        <span>6. Principios aplicables al Tratamiento de Datos Personales</span>
      </b>
      
      <br className="" id="" />
      <span>a) Principio de Legalidad: El tratamiento de datos personales se llevará a cabo de conformidad con las leyes y regulaciones aplicables en Colombia.</span>
      <br className="" id="" />
      <span>b) Principio de Finalidad: Los datos personales serán recolectados con fines determinados, explícitos y legítimos. No se realizará ningún tratamiento que sea incompatible con dichos propósitos.</span>
      <br className="" id="" />
      <span>c) Principio de Libertad: El tratamiento de datos personales solo se realizará con el consentimiento previo, expreso e informado del titular. El titular tiene la facultad de decidir sobre el tratamiento de sus datos personales y podrá abstenerse de otorgar la autorización solicitada.</span>
      <br className="" id="" />
      <span>d) Principio de Veracidad o Calidad: Los datos personales sujetos a tratamiento deben ser veraces, completos, exactos, actualizados y pertinentes de acuerdo con las finalidades para las cuales fueron recolectados. Se adoptarán medidas razonables para mantener la información actualizada.</span>
      <br className="" id="" />
      <span>e) Principio de Transparencia: TRET garantizará que el titular de los datos pueda acceder a la información sobre el tratamiento de sus datos personales y ejercer sus derechos de manera sencilla y efectiva.</span>
      <br className="" id="" />
      <span>f) Principio de Seguridad: Se adoptarán las medidas técnicas, administrativas y humanas necesarias para garantizar la seguridad de los datos personales y evitar su adulteración, pérdida, consulta, uso o acceso no autorizado.</span>
      <br className="" id="" />
      <span>g) Principio de Confidencialidad: Todas las personas que intervengan en el tratamiento de datos personales estarán obligadas a mantener la confidencialidad de la información, incluso después de finalizada su relación con TRET.</span>
      <br className="" id="" />
      <span>h) Principio de Acceso y Circulación Restringida: El acceso a los datos personales se limitará únicamente a las personas autorizadas que necesiten realizar su tratamiento de acuerdo con las finalidades establecidas.</span>
      <br className="" id="" />
      <span>i) Principio de Responsabilidad: TRET será responsable por el cumplimiento de la normativa de protección de datos y por garantizar el ejercicio de los derechos de los titulares.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      <b className="" id="">
        <span>7. Tratamiento de Datos Personales</span>
      </b>
      
      <br className="" id="" />
      <span>1) Recolección de Datos Personales: La recolección de datos debe esta sujeta a la autorización previa y expresa del titular, y solo se recopilarán los datos necesarios para cumplir con las finalidades establecidas en la política de tratamiento de datos personales de TRET. Los datos recolectados podrán ser (entre otros):</span>
      <br className="" id="" />
      <span>1.1 Información de Identificación Personal: Nombre completo, número de identificación (por ejemplo, cédula o pasaporte), fecha de nacimiento, género, estado civil, fotografía, firma y nacionalidad.</span>
      <br className="" id="" />
      <span>1.2 Información de Contacto: Dirección postal, número de teléfono fijo y/o móvil, dirección de correo electrónico y otra información para el contacto.</span>
      <br className="" id="" />
      <span>1.3 Información Financiera: Número de cuenta bancaria, extractos bancarios, información de tarjetas de crédito o débito, historial crediticio, ingresos y otros detalles relacionados con la situación financiera. </span>
      <br className="" id="" />
      <span>1.4 Información de Transacciones: Detalles de las transacciones realizadas, incluidos registros de pagos, transferencias, inversiones u otras operaciones financieras.</span>
      <br className="" id="" />
      <span>1.5 Información de Redes Sociales: Si el usuario vincula su cuenta de redes sociales con la plataforma,  se pueden recopilar información para verificación de Identidad: Documentos o información adicional requerida para verificar la identidad de los usuarios y cumplir con regulaciones de prevención de lavado de dinero y financiamiento del terrorismo.</span>
      <br className="" id="" />
      <span>2) Finalidad del Tratamiento </span>
      <br className="" id="" />
      <span>Las finalidades para las cuales se recolectarán los datos personales serán: </span>
      <br className="" id="" />
      <span>2.1 Efectuar las gestiones pertinentes para el desarrollo del objeto social de TRET, en lo que tiene que ver con el cumplimiento del objeto del contrato celebrado con el Titular de la información.</span>
      <br className="" id="" />
      <span>2.2 Realizar invitaciones a eventos y ofrecer nuevos productos y servicios.</span>
      <br className="" id="" />
      <span>2.3 Gestionar trámites (solicitudes, quejas, reclamos).</span>
      <br className="" id="" />
      <span>2.4 Efectuar encuestas de satisfacción respecto de los bienes y servicios</span>
      <br className="" id="" />
      <span>ofrecidos</span>
      <br className="" id="" />
      <span>2.5 Suministrar información de contacto a la fuerza comercial y/o red de distribución, telemercadeo, investigación de mercados y cualquier tercero con el cual TRET tenga un vínculo contractual para el desarrollo de actividades de ese tipo (investigación de mercados y telemercadeo, etc.) para la ejecución de las mismas.</span>
      <br className="" id="" />
      <span>2.6 Contactar al Titular a través de medios electrónicos – SMS o chat para el envío de noticias relacionadas con campañas de fidelización o mejora de servicio.</span>
      <br className="" id="" />
      <span>2.7 Contactar al Titular a través de correo electrónico para el envío de extractos, estados de cuenta o facturas en relación con las obligaciones derivadas del contrato celebrado entre las partes.</span>
      <br className="" id="" />
      <span>2.8 Prestar los servicios ofrecidos por TRET  y aceptados en el contrato suscrito. </span>
      <br className="" id="" />
      <span>TRET informará si habrá tratamientos adicionales no contemplados inicialmente y solicitará la autorización previa para ello.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      <b className="" id="">
        <span>8. Autorización</span>
      </b>
      
      <br className="" id="" />
      <span>La autorización es el consentimiento previo, expreso e informado que otorga el titular de los datos personales para que TRET lleve a cabo el tratamiento de sus datos.</span>
      <br className="" id="" />
      <span>a) Consentimiento Previo: La autorización debe ser otorgada antes de realizar cualquier tratamiento de datos personales. Por lo tanto, se solicitará el consentimiento del titular de manera previa a la recolección, uso, almacenamiento o cualquier otra acción relacionada con sus datos personales.</span>
      <br className="" id="" />
      <span>b) Consentimiento Expreso: La autorización debe ser clara, inequívoca y específica. Esto significa que el titular debe manifestar su acuerdo de manera expresa, ya sea a través de una declaración escrita, verbal o mediante una acción afirmativa clara, como marcar una casilla o seleccionar una opción que indique su consentimiento.</span>
      <br className="" id="" />
      <span>c) Consentimiento Informado: Antes de solicitar la autorización, TRET debe informar al titular sobre los propósitos para los cuales se recolectarán los datos personales, así como los derechos que le asisten como titular de los datos. También se le debe informar sobre la existencia de esta política de tratamiento de datos personales y cómo puede acceder a ella.</span>
      <br className="" id="" />
      <span>Revocatoria del Consentimiento:</span>
      <br className="" id="" />
      <span>El titular de los datos podrá revocar su consentimiento en cualquier momento, siempre y cuando no se afecten los derechos y obligaciones derivados de la relación jurídica existente. Para revocar el consentimiento, el titular deberá comunicarse con TRET a través de los canales establecidos para tal fin.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      <b className="" id="">
        <span>9. Derechos de los Titulares</span>
      </b>
      
      <br className="" id="" />
      <span>Como titular de sus datos personales Usted tiene derecho a:</span>
      <br className="" id="" />
      <span>a) Acceder de forma gratuita a los datos proporcionados que hayan sido objeto de tratamiento.</span>
      <br className="" id="" />
      <span>b) Conocer, actualizar y rectificar su información frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo tratamiento esté prohibido o no haya sido autorizado.</span>
      <br className="" id="" />
      <span>c) Solicitar prueba de la autorización otorgada.</span>
      <br className="" id="" />
      <span>d) Presentar ante la Superintendencia de Industria y Comercio (SIC) quejas por infracciones a lo dispuesto en la normatividad vigente. </span>
      <br className="" id="" />
      <span>e) Revocar la autorización y/o solicitar la supresión del dato, siempre que no exista un deber legal o contractual que impida eliminarlos.</span>
      <br className="" id="" />
      <span>f) Abstenerse de responder las preguntas sobre datos sensibles. Tendrá carácter facultativo las respuestas que versen sobre datos sensibles o sobre datos de las niñas y niños y adolescentes.</span>
      <br className="" id="" />
      
      <b className="" id="">
        
      <br className="" id="" />
      <span>10. Procedimiento Para El Ejercicio Del Derecho De Habeas Data</span>
      </b>
      
      <br className="" id="" />
      <span>En cumplimiento de las normas sobre protección de datos personales, TRET presenta el procedimiento y requisitos mínimos para el ejercicio de sus derechos:</span>
      <br className="" id="" />
      <span>Para la radicación y atención de su solicitud le solicitamos suministrar la siguiente información:</span>
      <br className="" id="" />
      <span>a) Nombre completo y apellidos</span>
      <br className="" id="" />
      <span>b) Datos de contacto (Dirección física y/o electrónica y teléfonos de c) contacto),</span>
      <br className="" id="" />
      <span>d) Medios para recibir respuesta a su solicitud,</span>
      <br className="" id="" />
      <span>e) Motivo(s)/hecho(s) que dan lugar al reclamo con una breve descripción del derecho que desea ejercer (conocer, actualizar, rectificar, solicitar prueba de la autorización otorgada, revocarla, suprimir, acceder a la información),</span>
      <br className="" id="" />
      <span>f) Firma (si aplica), y</span>
      <br className="" id="" />
      <span>g) Número de identificación.</span>
      <br className="" id="" />
      <span>El término máximo previsto por la ley para resolver su reclamación es de quince (15) días hábiles, contado a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, TRET informará al interesado los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término. Una vez cumplidos los términos señalados por la Ley 1581 de 2012 y las demás normas que la reglamenten o complementen, el Titular al que se deniegue, total o parcialmente, el ejercicio de los derechos de acceso, actualización, rectificación, supresión y revocación podrá poner su caso en conocimiento de la Superintendencia de Industria y Comercio –Delegatura para la Protección de Datos Personales.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      <b className="" id="">
        <span>11. El Servicio </span>
      </b>
      
      <br className="" id="" />
      <span>a) El servicio de TRET consiste en la construcción, proyección y optimización del flujo de caja del cliente.</span>
      <br className="" id="" />
      <span>b) El cliente proporcionará a través del portal de TRET en (www.tret.com.co) la información de extractos bancarios, saldos cuentas bancarias u otra información financiera que se requiera para la construcción del informe.</span>
      <br className="" id="" />
      <span>c) TRET enviará al correo electrónico del cliente el informe ajustado de acuerdo con las condiciones acordadas con el cliente.</span>
      <br className="" id="" />
      <span>d) La información que se proporcione en estos informes no deben tomarse como una asesoría, recomendación o sugerencia de parte de TRET para la toma de decisiones de inversión o la realización de cualquier tipo de transacciones o negocios. Esta información es puramente informativa y no constituyen una oferta, ni una demanda en firme, para la realización de transacciones.</span>
      <br className="" id="" />
      <span>e) En cualquier momento TRET, puede agregar, cambiar, terminar, remover o suspender cualquiera de los servicios prestados a través de nuestros Canales, siempre respetando los Términos y Condiciones de cada uno de los productos y o servicios contratados por ti como usuario. En relación con los productos y servicios prestados por terceros Aliados, TRET no será responsable por la terminación o suspensión en la prestación de estos.</span>
      <br className="" id="" />
      <span>f) A través de nuestros Canales, TRET ofrece de manera directa o en alianza con otras entidades, productos y servicios financieros y de educación financiera.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      <b className="" id="">
        <span>12. Cómo Acceder al Servicio de TRET</span>
      </b>
      
      <br className="" id="" />
      <span>a) Por su parte para acceder a TRET, deberás ser una persona jurídica o natural, mayor edad según esto se defina en el país en donde te encuentras y deberás contar con plena capacidad legal para obligarte y contratar.</span>
      <br className="" id="" />
      <span>b) El acceso a los servicios ofrecidos a través de TRET, requiere la creación de una cuenta en con la cual se surtirá el proceso de registro.</span>
      <br className="" id="" />
      <span>c) El proceso de registro inicia con la asignación de un usuario que corresponde a tu correo electrónico, este usuario será único, por lo cual no existe la posibilidad de que dos personas se registren bajo el mismo nombre de usuario. Adicionalmente, deberás proporcionar una contraseña que corresponde a una combinación alfanumérica previamente definida por ti. Tanto tu usuario como tu contraseña son de carácter personal, por lo tanto, al acceder al portal te comprometes a mantener en absoluta reserva esta información y a no compartirla ni divulgarla. De infringir las obligaciones de reserva de tu usuario y contraseña, se considerará que existe un incumplimiento de los presente Términos y Condiciones Generales de Uso de tu parte.</span>
      <br className="" id="" />
      <span>d) Al correo registrado llegará una notificación para la activación del usuario. Esto, con el fin de verificar que el registro corresponda a la persona que le pertenezca el dominio de la dirección de correo electrónico registrada.</span>
      <br className="" id="" />
      <span>e) El cliente debe contar con los medios físicos y dispositivos que te permitan acceder a nuestros canales, así como una conexión a internet segura para recibir o transmitir, vía internet, la información asociada a los productos y servicios prestados a través de TRET.</span>
      <br className="" id="" />
      <span>f) El mantenimiento y la seguridad de dichos equipos será de tu entera responsabilidad del cliente, por lo que es necesario que tomes las medidas necesarias para proteger tus equipos de cualquier tipo de software malicioso que comprometa la seguridad de tus dispositivos y con ello, la seguridad de la información con la que accedes a los canales de TRET.</span>
      <br className="" id="" />
      <span>13. Vigencia Política Tratamiento de Datos</span>
      <br className="" id="" />
      <span>La presente Política para el Tratamiento de Datos Personales rige a partir del 20 de julio de 2023.</span>
      <br className="" id="" />
      <span>Las bases de datos en las que se registrarán los datos personales tendrán una vigencia igual al tiempo en que se mantenga y utilice la información para las finalidades descritas en esta política. Una vez se cumplan esas finalidades y siempre que no exista un deber legal o contractual de conservar su información, sus datos serán eliminados de nuestras bases de datos. </span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      <b className="" id="">
        <span>13. Contacto</span>
      </b>
      
      <br className="" id="" />
      <span>Soporte es la dependencia que tiene a cargo dar trámite a las solicitudes de los titulares para hacer efectivos sus derechos a info@tret.com.co</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i5of" className="">
      
      <div id="i8lmjf" className="">
      
      <div id="im4a28" className="">
      
      <img
        className=""
        id="iteqmg"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkr5bykb.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="iqpkke" className="">
      
      <p className="" id="iy8adh">
        <span>Dirección</span>
      </p>
      
      <p className="" id="iiauur">
        <span>Bogotá, Colombia</span>
      </p>
      
      </div>
      
      <div id="imh7h6" className="">
      
      <p className="" id="ijoril">
        <span>Correo de contacto</span>
      </p>
      
      <p className="" id="ik2c1u">
        <span>info@tret.com.co</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iw4n9w" className="">
      
      <div id="ianz3m" className="">
      
      </div>
      
      <div id="iufalf" className="">
      
      <p className="" id="ij1xap">
        <span>Coyright©2023 TRET - Todos los derechos reservados.</span>
      </p>
      
      <div id="ielirh" className="">
      
      <img
        className=""
        id="ii3z3k"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk14hfve.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="i1414e"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk14i6xh.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="it3ooa"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk14iokp.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pterminosycondiciones);
  

import React from 'react';


import {connect} from 'react-redux';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';
import {loftyLoginWithEmail, loftyCreateUser, loftyCreateResetToken, loftyResetPassword, loftyChangePassword, loftyUpdateUserInfo, loftyVerifyEmail} from '../../api/user.api';


import './olvidarcontrasena.scss';






const Polvidarcontrasena = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {




  
  
  
  

  
  return (
    <div id="id653fb4d2c239522da2e3a90e" >

      <div id="fgfg" className="">
      
      <div id="i99szh" className="">
      
        <a
          target=""
          className=""
          id="i9ymbj"
          href="/"
        >
          
      <img
        className=""
        id="i1d1f1"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkr0oo7y.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="ix5d2" className="">
      
      <div id="iw403" className="">
      
      <p className="" id="iegvfq">
        <span>Olvidar Contraseña</span>
      </p>
      
      <form
        id="ikzmj"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					const loftyEmail = e.target.emailReqPasswordReset?.value;
					const res = await loftyCreateResetToken({ email: loftyEmail })
				} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="" className="formForgotPasswordDiv ">
      
      <label id="inchm" className="labeliniciosesion ">
      <span>Email:</span>
      </label>
      
      <input
        name="emailReqPasswordReset"
        type="email"
        placeholder=""
        className="inputdark "
        id="izmdd"
      />
      
      <button
        type="submit"
        name=""
        id="iw95z"
        className="buttoniniciosesion "
        
      >
      <span>Enviar</span>
      </button>
      
      </div>
      
      </form>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Polvidarcontrasena);
  
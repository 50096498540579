
import React from 'react';


import {connect} from 'react-redux';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';
import {loftyLoginWithEmail, loftyCreateUser, loftyCreateResetToken, loftyResetPassword, loftyChangePassword, loftyUpdateUserInfo, loftyVerifyEmail} from '../../api/user.api';


import './registrousuario.scss';






const Pregistrousuario = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {




  
  
  
  

  
  return (
    <div id="id653fa84ec239522da2e3a820" >

      <div id="fgfg" className="">
      
      <div id="i99szh" className="">
      
        <a
          target=""
          className=""
          id="i9ymbj"
          href="/"
        >
          
      <img
        className=""
        id="i1d1f1"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkr0oo7y.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="ix5d2" className="">
      
      <div id="iw403" className="">
      
      <p className="" id="iegvfq">
        <span>Registro</span>
      </p>
      
    <div>
      
      <form
        id="iwnzk"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					const loftyEmail = e.target.loftyEmail?.value;
					const loftyPassword = e.target.loftyPassword?.value;
					const res = await loftyCreateUser({
						loftyEmail: e.target.loftyEmail?.value,
						telephone: e.target.telephone?.value,
						loftyName: e.target.loftyName?.value,
						loftyPassword: e.target.loftyPassword?.value,
					});
					addLoftyTokenApp({ loftytoken: res.token, loftyuser: res.user });
					window.location.href = '/dashboard';
				} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
    <div id="iophe" className="">
    
      <label id="ig7qj" className="labeliniciosesion ">
      <span>Nombre Completo</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="loftyName"
        type="text"
        placeholder="Nombre completo"
        className="inputdark "
        id="ip7cg"
      />
      
    </div>
    
      <div id="in993" className="">
      
      <label id="i237v" className="labeliniciosesion ">
      <span>Correo</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="loftyEmail"
        type="text"
        placeholder="Correo"
        className="inputdark "
        id="i1hd4"
      />
      
      </div>
      
      <div id="ia8ql" className="">
      
      <label id="" className="">
      <span>Telefono</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="telephone"
        type="text"
        placeholder="Telefono"
        className="inputdark "
        id="is9nm"
      />
      
      </div>
      
      <div id="i29cc" className="">
      
      <label id="il8ch" className="labeliniciosesion ">
      <span>Contraseña</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="loftyPassword"
        type="password"
        placeholder="Contraseña"
        className="inputdark "
        id="iten1"
      />
      
      </div>
      
      <button
        type="submit"
        name=""
        id="ivo98"
        className="buttoniniciosesion "
        
      >
      <span>Registrarse</span>
      </button>
      
      </form>
      
    </div>
    
      <div id="ikf7l" className="">
      
        <a
          target=""
          className="buttoniniciosesion "
          id="i6z1y"
          href="/signin"
        >
          
    <div id="igrxm" className="">
    <span>Iniciar sesión</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pregistrousuario);
  

import React from 'react';


import {connect} from 'react-redux';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';


import './paginasobretret4au2r.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Ppaginasobretret4au2r = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {




  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id67102744a68f26b67983bf9c" >

      <div id="iw4j" className="">
      
      <div id="ips2" className="">
      
      <div id="itmdc" className="">
      
      <img
        className=""
        id="ismeq"
        src="https://assetsprojects.s3.amazonaws.com/404m4lim23uvzm3.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="iacot" className="">
      
        <LoftyDropdownComponent
          id="iejkse"
          className="gjs-dropdown-menu-container link-navbar "
          childrenShow={
            <>
              <div id="icmp9k" className="gjs-dropdown-menu-trigger ">
<span>Servicios</span></div>

            </>
          }
          childrenHover={
            <>
              <div id="ifwrr4" className="gjs-dropdown-menu-content " style={{display: 'block'}}>

      <a
        target=""
        className="link-navbar "
        id="ixxmu"
        href="/tret"
        type="button"
      >
        
    <div id="ikc1x" className="">
    <span>TRET</span>
    </div>
    
      </a>
      
      <a
        target=""
        className="link-navbar "
        id="i2hsg"
        href="/"
        type="button"
      >
        
    <div id="ij3vk" className="">
    <span>CFO Externo</span>
    </div>
    
      </a>
      </div>

            </>
          }
        />
        
      <a
        target=""
        className="link-navbar "
        id="ibmm3s"
        href="/sobrenosotros"
        type="button"
      >
        
    <div id="iol6vk" className="">
    <span>Sobre Nosotros</span>
    </div>
    
      </a>
      
      <a
        target=""
        className="link-navbar "
        id="i8ax7g"
        href="/blog"
        type="button"
      >
        
    <div id="idaz8h" className="">
    <span>Contenido</span>
    </div>
    
      </a>
      
      </div>
      
      <div id="ipgm41" className="">
      
        <a
          target=""
          className=""
          id="i7mm6p"
          href="#"
        >
          
      <img
        className=""
        id="ip8s2j"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilyoqjlwm.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="ij95fz"
          href="#"
        >
          
      <img
        className=""
        id="ipfg0k"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilyoqmjqh.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="izkwo7"
          href="#"
        >
          
      <img
        className=""
        id="il4qxw"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilyoqn9j4.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <img
        className=""
        id="iok6z3"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkvb1chy.jpg"
        alt="undefined"
      />
      
      <div id="icq3pj" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Home</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>About</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Team</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Contact</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Twitter</span>
      </a>
      
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ihk8" className="">
      
      <div id="it6xg" className="">
      
      <p className="" id="ip3sl">
        <span>TRET</span>
      </p>
      
      <p className="" id="ir8ccl">
        <span>Servicios &gt; TRET</span>
      </p>
      
      <div id="ibin0r" className="">
      
        <a
          target=""
          className="botonprimary "
          id="i1ezla"
          href="#"
        >
          
    <div id="icfw7s" className="">
    <span>Contáctanos</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      <div id="if5pi" className="">
      
      <p className="" id="i803s">
        <span>La única herramienta que necesitas para manejar el flujo de caja de tu empresa de forma fácil y profesional</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>- Genera más rentabilidad con la liquidez de tu empresa.</span>
      <br className="" id="" />
      <span>- Reduce costos financieros innecesarios.</span>
      <br className="" id="" />
      <span>- Gana velocidad en la toma de decisiones sobre tu liquidez.</span>
      <br className="" id="" />
      <span>- Alarmas eficientes: Nuevas oportunidades de mercado o posibles escenarios de estrés de tu liquidez</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i6xx" className="">
      
      <div id="i0tfl" className="">
      
      <div id="ivvvb5" className="">
      
      <div id="iyqmu8" className="">
      
      </div>
      
      </div>
      
      <div id="im4sk2" className="">
      
          <div id="i22j24" className="">
            <iframe
              title="Video Youtube"
              src="https://www.youtube.com/embed/Wih8C0cGXfk?"
              allowFullScreen="undefined"
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </div>
          
      </div>
      
      <div id="it9es4" className="">
      
      <div id="irbv49" className="">
      
          <div id="i0y2lo" className="">
            <iframe
              title="Video Youtube"
              src="https://www.youtube.com/embed/_kFgJqg-sp8?"
              allowFullScreen="undefined"
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </div>
          
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="isbhos" className="">
      
      <div
        id="iq3f0x"
        className=""
        data-aos="fade-up"
        data-aos-duration="undefined"
      >
      
      <div id="i3caqq" className="">
      
      <div id="irc04x" className="">
      
      <div id="i43gtg" className="">
      
      <div id="i39utq" className="">
      
      <p className="subtitulo " id="i6p8b1">
        <span>¿Cómo puedo sacar el mejor provecho a la liquidez de mi empresa?</span>
      </p>
      
      <p className="descripcion2 " id="i1mwly">
        <span>La liquidez de tu empresa puede que no esté generando mucho en este momento. La razón se encuentra comúnmente escondida en tu flujo de caja.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>¡Sí, ese mismo!, el que te dice cuánto tienes hoy, cuánto vas a recaudar y pagar en éste y los próximos meses.</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      <div id="ibf9kj" className="">
      
      <img
        className=""
        id="i2nnhu"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk12kfjc.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="i57dji" className="">
      
      <div id="itrilx" className="">
      
      <img
        className=""
        id="iyleqi"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkfv1u1c.jpg"
        alt="undefined"
      />
      
      </div>
      
      <div id="i2hmye" className="">
      
      <p className="subtitulo " id="i1mwnf">
        <span>¿PODEMOS AYUDARTE EN LA GESTIÓN FINANCIERA DE TU EMPRESA? </span>
      </p>
      
      <p className="descripcion2 " id="ivi9ki">
        <span>Tenemos las habilidades de un buen gerente Financiero en el manejo eficiente de los recursos, detectar riesgos financieros y no menos importante, negociar las mejores condiciones bancarias</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      <div id="ic07ai" className="">
      
      <div id="ildooc" className="">
      
      <p className="subtitulo " id="ijgi5b">
        <span>¿Demasiado ocupado para ver todas las oportunidades financieras?</span>
      </p>
      
      <p className="descripcion2 " id="ip083n">
        <span>Sabemos lo retador que es mantener tu negocio funcionando y queremos ayudarte a reducir la carga.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Por esto, a partir de una poca información financiera, TRET te construye y analiza tu flujo de caja, monitorea los productos financieros disponibles y encuentra las mejores respuestas para que puedas rentabilizar tu liquidez.</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      <div id="ieu90w" className="">
      
      <img
        className=""
        id="ibg6qj"
        src="https://assetsprojects.s3.amazonaws.com/405opfilklpr4en.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="ipheoy" className="">
      
      <div id="iyr2ub" className="">
      
      <img
        className=""
        id="irm6ox"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkcxaspf.jpg"
        alt="undefined"
      />
      
      </div>
      
      <div id="ih3fni" className="">
      
      <p className="subtitulo " id="iy8kez">
        <span>¿Quieres mantener las cosas simples? </span>
      </p>
      
      <p className="descripcion2 " id="i20htm">
        <span>Los softwares normalmente requieren horas de tu tiempo y de tu equipo para realizar su instalación y mantenimiento, pero realmente no te dan respuestas claras sobre lo que debes hacer.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>TRET te da las respuestas que estas buscando para tener un manejo óptimo de tu flujo de caja, lo que te permitirá tener más tiempo para las cosas importantes, como enfocarte en tú negocio, tu familia o tus hobbies.</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      <p className="" id="iyyhre">
        
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="irqujg" className="">
      
      <div id="iwqp1k" className="">
      
      <div id="i06tr1" className="">
      
      <img
        className=""
        id="iv7kbi"
        src="https://assetsprojects.s3.amazonaws.com/405opfilku9i145.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="iyw1nu" className="">
      
      <div id="i2girj" className="">
      
      <p className="" id="i1l31g">
        <span>Simula tu inversión en liquidez</span>
      </p>
      
      <p className="" id="iwuzzj">
        <span>Ganas Más con TRET</span>
      </p>
      
      </div>
      
      <div id="i2ngo3" className="">
      
      <p className="" id="i33d6b">
        <span>¿Te gustaría saber cómo crecería tu dinero con una estrategia de inversión inteligente?</span>
      </p>
      
      <div id="i4mcz4" className="">
      
        <div id="i4s1ml" className="">
          
      <input
        name=""
        type="text"
        placeholder=""
        className=""
        id="i8oek4"
      />
      
        </div>
      
      <button
        type=""
        name=""
        id="ic7yd7"
        className="botonprimary "
        
      >
      
      <div id="iayu2l" className="">
      <span>Simular</span>
      </div>
      
      </button>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="icohi" className="">
      
      <div id="iyghfd" className="">
      
      <div id="i52pnr" className="">
      
      <div id="iys268" className="">
      
      <img
        className=""
        id="imi9j3"
        src="https://assetsprojects.s3.amazonaws.com/404m4lim0h22y66.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="iyo7r6">
        <span>Externaliza tu CFO con TRET</span>
      </p>
      
      <p className="" id="iygu8i">
        
      <a
        target=""
        className=""
        id="ionor8"
        href="mailto:gerencia@tret.com.co"
        type=""
      >
        <span>gerencia@tret.com.co</span>
      </a>
      
      </p>
      
      </div>
      
      <div id="id3akf" className="">
      
      <p className="" id="i160fk">
        <span>Dirección</span>
      </p>
      
      <p className="" id="igdgp1">
        <span>Carrera 8 #85-31, Bogotá, Colombia</span>
      </p>
      
      <p className="" id="io8u0g">
        
      <a
        target=""
        className=""
        id="ivbmuk"
        href="mailto:gerencia@tret.com.co"
        type=""
      >
        <span>gerencia@tret.com.co</span>
      </a>
      
      </p>
      
      </div>
      
      <div id="ipuzkj" className="">
      
      <p className="" id="ifh7p5">
        <span>Contacto</span>
      </p>
      
      <p className="" id="il1rnw">
        
      <a
        target=""
        className=""
        id="ic38zj"
        href="mailto:gerencia@tret.com.co"
        type=""
      >
        <span>gerencia@tret.com.co</span>
      </a>
      
      </p>
      
      </div>
      
      </div>
      
      <div id="iou1pv" className="">
      
      <p className="" id="izq2dp">
        <span>Todos lo derechos reservados</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Ppaginasobretret4au2r);
  
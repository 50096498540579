
import React, {useState} from 'react';

const LoftyDropdownComponent = ({
  id, className, childrenShow, childrenHover
}) => {
const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={className}
      id={id}
    >
      {childrenShow}
      {
        isHovered ? (
          <>
            {childrenHover}
          </>
        ) : <></>
      }
    </div>
  );
  
}

export default LoftyDropdownComponent;



import React from 'react';


import {connect} from 'react-redux';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';


import './khristian3.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Pkhristian3 = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {




  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id64cc13759255464525645782" >

      <div id="i55h" className="">
      
      <div id="id6iic" className="">
      
        <a
          target=""
          className=""
          id="ikuk3f"
          href="/"
        >
          
      <img
        className=""
        id="i9pt4f"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkr1t22z.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/quienessomos"
        type=""
      >
        <span>Quienes Somos</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/aprende"
        type=""
      >
        <span>Aprende</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/#ijf0dn"
        type=""
      >
        <span>Preguntas Frecuentes</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/#ijn4q"
        type=""
      >
        <span>Contáctanos</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      <div id="izuf7m" className="">
      
        <a
          target=""
          className=""
          id="i3mkrh"
          href={`/quienessomos`}
        >
          
      <p className="" id="ilb64w">
        <span>Quiénes Somos</span>
      </p>
      
        </a>
        
        <a
          target=""
          className=""
          id="ibhmfa"
          href="#"
        >
          
      <p className="" id="il1q3h">
        <span>Aprende</span>
      </p>
      
        </a>
        
        <a
          target=""
          className=""
          id="i7q4zk"
          href={`/pruebasluis`}
        >
          
      <p className="" id="it6im4">
        <span>Preguntas Frecuentes</span>
      </p>
      
        </a>
        
        <a
          target=""
          className=""
          id="ilbw8k"
          href={`/pruebasluis`}
        >
          
      <p className="" id="ixd91v">
        <span>Contáctanos</span>
      </p>
      
        </a>
        
      </div>
      
      </div>
      
      <div id="icv7c" className="">
      
      </div>
      
      <div id="ifxad2" className="">
      
      <div id="ijzglu" className="">
      
      <p className="" id="isdsyu">
        <span>¿Cómo te apoyamos en tu ROL de Gerente Financiero?</span>
      </p>
      
      <div id="icj91z" className="">
      
      <p className="" id="ibmtil">
        
      <span className="" id="">
        
      <span className="" id="i9wnay">
        <span>Todas las empresas, sin importar su naturaleza, deben llevar a cabo una adecuada administración financiera para garantizar la sostenibilidad a lo largo del tiempo. Para lograr una visión clara en este aspecto, es fundamental identificar las actividades mínimas que toda gerencia de una PYME debe realizar para asegurar una óptima gestión financiera.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Lo segundo, es saber que el mundo de la administración financiera se divide en 2 partes o columnas vertebrales: la rentabilidad y la liquidez de la empresa. </span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>La rentabilidad es una medida clave para evaluar la viabilidad a largo plazo de un negocio. Se basa en el hecho de que las ventas del producto o servicio que ofrecemos deben generar un retorno adicional que cubra todos los costos asociados con la empresa. A menudo, esta cuestión se resuelve con la intuición y habilidades del empresario a medida que transcurre el tiempo.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>La liquidez es una evaluación esencial para determinar la viabilidad del negocio en diferentes plazos (corto, mediano y largo plazo). Su análisis se centra principalmente en el flujo de caja, lo que permite anticipar las mejores opciones de inversión a la vista (evitando dejar la menor plata posible sobre la mesa) o encontrar líneas de crédito que se adapten adecuadamente a las necesidades del negocio.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Si tuviéramos que determinar cuál es más crucial, sin duda, la liquidez nos brindaría la posibilidad de mantenernos a flote mientras encontramos el punto de equilibrio en la empresa. No es en vano que existe el dicho popular de que "el efectivo es el rey".</span>
      <br className="" id="" />
      <span>A continuación, resumimos las mínimas funciones que se debe ejercer en la administración financiera dentro de una empresa. </span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Las </span>
      <b className="" id="">
        
      <u className="" id="">
        <span>5 Principales Funciones</span>
      </u>
      
      </b>
      <span> que debe hacer un Gerente General como Financiero:</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      <b className="" id="">
        <span>1. </span>
      </b>
      <span>Decidir y Gestionar eficiente el capital de trabajo de la empresa, las diferentes fuentes de financiación con que cuenta la empresa (negociación con bancos y proveedores) e inversión de los excedentes temporales de liquidez.</span>
      <br className="" id="" />
      
      <b className="" id="">
        <span>2. </span>
      </b>
      <span>Negociar las políticas de crédito a los clientes, pago a proveedores y costos bancarios. </span>
      <br className="" id="" />
      
      <b className="" id="">
        <span>3.</span>
      </b>
      <span> Conocer a fondo la estructura financiera de la empresa, su nivel de activos, pasivos, patrimonio, flujo de caja y velar por tener indicadores financieros saludables para cumplir con sus diferentes obligaciones.</span>
      <br className="" id="" />
      
      <b className="" id="">
        <span>4. </span>
      </b>
      <span>Supervisar la elaboración de los estados financieros (liderar la contabilidad).</span>
      <br className="" id="" />
      
      <b className="" id="">
        <span>5.</span>
      </b>
      <span> Liderar la planeación financiera de mediano y largo plazo conociendo el entorno Macroeconómico para proyectar las necesidades financieras de la Empresa, evaluar los riesgos asociados y los planes de crecimiento a futuro.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Entendemos que para que un gerente general pueda cumplir cabalmente con estas funciones, requeriría invertir muchas horas de trabajo, lo cual podría restarle tiempo valioso para enfocarse en la misión principal de su negocio.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Estamos presentando una solución que simplifica significativamente este proceso, proporcionando la claridad necesaria desde el inicio al construir y trabajar con el flujo de caja de tu empresa. A partir de los datos primarios, convertimos esta información en respuestas concretas y listas para su aplicación.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Desafortunadamente, La mayoría de los Software financieros son medios para poder ejecutar la estrategia financiera, pero no constituyen la estrategia en sí misma. Esto hace que no generen las alarmas necesarias para prever faltantes de caja o para brindar las mejores opciones de inversión.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      <u className="" id="">
        
      <b className="" id="">
        <span>Conclusiones</span>
      </b>
      
      </u>
      
      <br className="" id="" />
      <span>Con más de 20 años de experiencia en el sector financiero, comprendemos que una gestión financiera efectiva comienza al comprender plenamente el estado de liquidez de la empresa. Reconocemos que en la actualidad, es responsabilidad del flujo de caja proporcionar las respuestas al gerente, en lugar de que sea el gerente quien busque las respuestas en el flujo de caja</span>
      <br className="" id="" />
      
      <b className="" id="">
        
      <u className="" id="">
        <span>Empieza hoy</span>
      </u>
      
      </b>
      
      <br className="" id="" />
      <span>TRET es la verdadera simplificación que necesitas para capitalizar oportunidades y disfrutar de la tranquilidad necesaria para enfocarte en lo más importante: tu negocio.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Empieza hoy agendado una llamada consultiva sin compromiso TRET a Treasury Tool</span>
      <br className="" id="" />
      
      </span>
      
      </span>
      
      <br className="" id="" />
      
      <p className="MsoListParagraphCxSpFirst " id="i4cneg">
        
      <b className="" id="i44ifv">
        
      </b>
      
      </p>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i5of" className="">
      
      <div id="i8lmjf" className="">
      
      <div id="im4a28" className="">
      
      <img
        className=""
        id="iteqmg"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkr5bykb.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="iqpkke" className="">
      
      <p className="" id="iy8adh">
        <span>Dirección</span>
      </p>
      
      <p className="" id="iiauur">
        <span>Ciudad de Bogotá, Colombia</span>
      </p>
      
      </div>
      
      <div id="imh7h6" className="">
      
      <p className="" id="ijoril">
        <span>Correo de contacto</span>
      </p>
      
      <p className="" id="ik2c1u">
        <span>info@tret.com.co</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iw4n9w" className="">
      
      <div id="ianz3m" className="">
      
      </div>
      
      <div id="iufalf" className="">
      
      <p className="" id="ij1xap">
        <span>Coyright©2023 TRET - Todos los derechos reservados.</span>
      </p>
      
      <div id="ielirh" className="">
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pkhristian3);
  

import React from 'react';


import {connect} from 'react-redux';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';


import './verifiedemail.scss';






const Pverifiedemail = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {




  
  
  
  

  
  return (
    <div id="id656772f58d2e0931f97bdecb" >

      <div id="fgfg" className="">
      
      <div id="i99szh" className="">
      
        <a
          target=""
          className=""
          id="i9ymbj"
          href="#"
        >
          
      <img
        className=""
        id="i1d1f1"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkr0oo7y.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="ix5d2" className="">
      
      <div id="iw403" className="">
      
      <p className="" id="iegvfq">
        <span>¡Correo verificado exitosamente!</span>
      </p>
      
      <p className="" id="ibc69">
        <span>Te damos una cordial bienvenida y esperamos tengas una experiencia exitosa y fructífera dentro de nuestra plataforma.</span>
      <br className="" id="" />
      <span>¡Haz click en el siguiente botón para comenzar!</span>
      </p>
      
      <div id="ikf7l" className="">
      
        <a
          target=""
          className="buttoniniciosesion "
          id="i6z1y"
          href="/signin"
        >
          
    <div id="igrxm" className="">
    <span>Iniciar Sesión</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pverifiedemail);
  
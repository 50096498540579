
import React, {useEffect, useState} from 'react';

import LoftyCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {connect} from 'react-redux';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {getAllclientestcsz7 } from '../../api/clientestcsz7.api'

import './landing1xyi8.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Planding1xyi8 = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {

	const [loftyendpoint_670844473b1b93d52424ae52, setLoftyEndpoint_670844473b1b93d52424ae52] = useState([]);
	
useEffect(() => {
		const loftyHandleData = async () => {
			const loftyres_670844473b1b93d52424ae52 = await getAllclientestcsz7({ token: loftytoken || 'asdsa' });
			setLoftyEndpoint_670844473b1b93d52424ae52(loftyres_670844473b1b93d52424ae52.data);
		}
		loftyHandleData();
	}, []);


  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id668be0d0914da0d427fe93f3" >

      <div id="iw4j" className="">
      
      <div id="ips2" className="">
      
      <div id="itmdc" className="">
      
      <img
        className=""
        id="ismeq"
        src="https://assetsprojects.s3.amazonaws.com/404m4lim23uvzm3.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="iacot" className="">
      
        <LoftyDropdownComponent
          id="iejkse"
          className="gjs-dropdown-menu-container link-navbar "
          childrenShow={
            <>
              <div id="icmp9k" className="gjs-dropdown-menu-trigger ">
<span>Servicios</span></div>

            </>
          }
          childrenHover={
            <>
              <div id="ifwrr4" className="gjs-dropdown-menu-content " style={{display: 'block'}}>

      <a
        target=""
        className="link-navbar "
        id="ixxmu"
        href="/tret"
        type="button"
      >
        
    <div id="ikc1x" className="">
    <span>TRET</span>
    </div>
    
      </a>
      
      <a
        target=""
        className="link-navbar "
        id="i2hsg"
        href="/"
        type="button"
      >
        
    <div id="ij3vk" className="">
    <span>CFO Externo</span>
    </div>
    
      </a>
      </div>

            </>
          }
        />
        
      <a
        target=""
        className="link-navbar "
        id="ibmm3s"
        href="/sobrenosotros"
        type="button"
      >
        
    <div id="iol6vk" className="">
    <span>Sobre Nosotros</span>
    </div>
    
      </a>
      
      <a
        target=""
        className="link-navbar "
        id="i8ax7g"
        href="/blog"
        type="button"
      >
        
    <div id="idaz8h" className="">
    <span>Contenido</span>
    </div>
    
      </a>
      
      </div>
      
      <div id="ipgm41" className="">
      
        <a
          target=""
          className=""
          id="i7mm6p"
          href="#"
        >
          
      <img
        className=""
        id="ip8s2j"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilyoqjlwm.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="ij95fz"
          href="#"
        >
          
      <img
        className=""
        id="ipfg0k"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilyoqmjqh.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="izkwo7"
          href="#"
        >
          
      <img
        className=""
        id="il4qxw"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilyoqn9j4.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <img
        className=""
        id="iok6z3"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkvb1chy.jpg"
        alt="undefined"
      />
      
      <div id="icq3pj" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Home</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>About</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Team</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Contact</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Twitter</span>
      </a>
      
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ihk8" className="">
      
      <div id="it6xg" className="">
      
      <p className="" id="ir8ccl">
        <span>Financieros enfocados en crear valor en Startups y Pymes  </span>
      </p>
      
      <p className="" id="ip3sl">
        <span>Externaliza tu CFO con TRET</span>
      </p>
      
        <a
          target=""
          className="botonprimary "
          id="iygz58"
          href="#"
        >
          
    <div id="i9ywzc" className="">
    <span>Contáctanos</span>
    </div>
    
        </a>
        
      </div>
      
      <div id="if5pi" className="">
      
      <p className="" id="i803s">
        <span>Descubre cómo podemos ayudarte a maximizar el valor de tu empresa, personalizando soluciones financieras para el momento actual de tu negocio</span>
      </p>
      
      <div id="if2hqk" className="">
      
      <img
        className=""
        id="iwhka9"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilyd7tcam.jpg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i6xx" className="">
      
      <div id="i0tfl" className="">
      
      <div id="ivvvb5" className="">
      
      <div id="iyqmu8" className="">
      
      <p className="" id="ia652r">
        <span>Nuestra experiencia en diferentes sectores nos permite tener una visión practica y estratégica de tu empresa</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="im4sk2" className="">
      
      <div id="ir78x3" className="">
      
      <p className="" id="ivwm6e">
        <span>20%</span>
      </p>
      
      <p className="" id="ioggqi">
        <span>Del costo que significa tener un gerente financiero de planta</span>
      </p>
      
      </div>
      
      <div id="ijr9pj" className="">
      
      <p className="" id="ib9eki">
        <span>+8 horas semanales</span>
      </p>
      
      <p className="" id="i7m5pn">
        <span>Liberarás tiempo valioso, que podrás enfocar en el core de tu empresa  </span>
      </p>
      
      </div>
      
      </div>
      
      <div id="it9es4" className="">
      
      <div id="irbv49" className="">
      
      <p className="servicios " id="ihtsq2">
        <span>Servicio de CFO</span>
      </p>
      
      <p className="servicios " id="iy8ixg">
        <span>Eficiencia y ahorro</span>
      </p>
      
      <div id="iy6kjn" className="">
      
      </div>
      
      <p className="servicios " id="iy1p8n">
        <span>Expertos en finanzas</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i3dh" className="">
      
      <p className="" id="i93l3">
        <span>Nos hemos convertido en un aliado para las empresas </span>
      <b className="" id="">
        <span>sin importar su industria</span>
      </b>
      
      </p>
      
      <div id="in7yz3" className="">
      
      <div
        id="ina81l"
        className=""
        data-aos="fade-up"
        data-aos-duration="2000"
      >
      
        <div id="ixj2u7" className="">
          
            <div>
              <LoftyCarousel
                responsive={{
                  superLargeDesktop: {
                    breakpoint: {max: 4000, min: 3000},
                    items: 5,
                  },
                  desktop: {
                    breakpoint: {max: 3000, min: 1024},
                    items: 3,
                  },
                  tablet: {
                    breakpoint: {max: 1024, min: 464},
                    items: 3,
                  },
                  mobile: {
                    breakpoint: {max: 464, min: 0},
                    items: 1,
                  },
                }}
                infinite
                autoPlay
              >
                {
                  loftyendpoint_670844473b1b93d52424ae52.map((loftyitem) => (
                    <div key={loftyitem._id} className="" id="iauerl">
                      
      <div id="iahxb3" className="">
      
        <img
          className=""
          id="iuc0og"
          alt=""
          src={loftyitem.loftyUrl}
        />
        
      </div>
      
                    </div>
                  ))
                }
              </LoftyCarousel>
            </div>
            
        </div>
        
      </div>
      
      </div>
      
      </div>
      
      <div id="i7qj2" className="">
      
      <div id="innv15" className="">
      
      <div id="i987ev" className="">
      
      <p className="seccion-info " id="i7m4ws">
        
      <br className="" id="" />
      
      </p>
      
      <p className="titulo-seccion " id="ilumsw">
        <span>Finanzas Estratégicas</span>
      </p>
      
      </div>
      
      <div id="ibpnu5" className="">
      
      <p className="descripcion-seccion " id="i9ttxg">
        <span>Diseñamos el kit financiero que genera más valor a tu empresa. </span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i7siqb" className="">
      
      <div
        id="i83x0r"
        className=""
        data-aos="fade-up"
        data-aos-duration="undefined"
      >
      
      <div id="i33brf" className="tarjeta-1 ">
      
      <div id="i9dbzo" className="">
      
      <div id="iwl4dh" className="tarjeta-contenido-superior ">
      
      <div id="iew0z4" className="">
      
      <div id="ikllzd" className="">
      
      <img
        className=""
        id="i0r9n1"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilyop8x0z.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="io0uqc" className="">
      
      <p className="" id="i9nin1">
        <span>Eficacia</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i1h1ui" className="">
      
      <p className="" id="ifkloh">
        <span>Alineamos la contabilidad, el presupuesto y  el flujo de caja de tu empresa para poder brindarte </span>
      <span className="gjs-hovered " id="ilfmdu-2">
        <span>una visión practica del estado actual del negocio y poder analizar ahorros de costos y/o nuevas inversiones</span>
      </span>
      
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div
        id="in782c"
        className=""
        data-aos="fade-up"
        data-aos-duration="undefined"
      >
      
      <div id="itcwij" className="tarjeta-1 ">
      
      <div id="i7jdco" className="">
      
      <div id="ir7g1t" className="tarjeta-contenido-superior ">
      
      <div id="ietht8" className="">
      
      <div id="iawlst" className="">
      
      <img
        className=""
        id="imtqfh"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilyopgwus.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="ilytiy" className="">
      
      <p className="" id="i0r8o4">
        <span>Eficiencia</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ieai39" className="">
      
      <p className="" id="inl5ao">
        <span>Confiar en profesionales para el análisis financiero y la proyección de tu empresa te permitirá generar más valor a la empresa, ahorrando tiempo y dinero valioso</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div
        id="ixax6f"
        className=""
        data-aos="fade-up"
        data-aos-duration="undefined"
      >
      
      <div id="i3ng7p" className="tarjeta-1 ">
      
      <div id="idyhxi" className="">
      
      <div id="inooqa" className="tarjeta-contenido-superior ">
      
      <div id="itekaj" className="">
      
      <div id="iwocwh" className="">
      
      <img
        className=""
        id="ir6woq"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilyopibyi.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="ihr8qa" className="">
      
      <p className="" id="i00og4">
        <span>Confianza</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ice414" className="">
      
      <p className="" id="i7335b">
        <span>Nuestro servicio de gerente financiero te permite externalizar uno de los roles más cruciales de tu empresa, </span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Trabaja con expertos en finanzas empresariales que te ayudarán a navegar en los desafíos actuales empresariales y económicos.</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="isbhos" className="">
      
      <div
        id="iq3f0x"
        className=""
        data-aos="fade-up"
        data-aos-duration="undefined"
      >
      
      <div id="i3caqq" className="">
      
      <div id="irc04x" className="">
      
      <div id="i43gtg" className="">
      
      <div id="i39utq" className="">
      
      <p className="subtitulo " id="i6p8b1">
        <span>Optimización De Costos</span>
      </p>
      
      <p className="descripcion2 " id="i1mwly">
        <span>Tener un gerente financiero de planta puede ser muy costoso. y la realidad es que muchas empresas no necesitan un financiero full-time </span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      <div id="ibf9kj" className="">
      
      <p className="contenido-significativo " id="i1zxie">
        <span>15% - 20%</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i57dji" className="">
      
      <div id="itrilx" className="">
      
      <p className="contenido-significativo " id="id6wph">
        <span>+10 años</span>
      </p>
      
      </div>
      
      <div id="i2hmye" className="">
      
      <p className="subtitulo " id="i1mwnf">
        <span>Nuestra Experiencia</span>
      </p>
      
      <p className="descripcion2 " id="ivi9ki">
        <span>Nuestra experiencia en diferentes sectores nos permite darle una visión practica y estratégica  de su negocio teniendo en cuenta el tamaño, su actual momento financiero, sus fortalezas y puntos de mejora.  </span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      <div id="ic07ai" className="">
      
      <div id="ildooc" className="">
      
      <p className="subtitulo " id="ijgi5b">
        <span>Tenernos de Aliados</span>
      </p>
      
      <p className="descripcion2 " id="ip083n">
        <span>Tener a la mano un aliado con la experiencia para ayudar a las diferentes áreas involucradas en el proceso financiero ayudará a la gerencia a liberar al menos  8 horas semanales</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      <div id="ieu90w" className="">
      
      <p className="contenido-significativo " id="iz2wph">
        <span>8 horas</span>
      </p>
      
      </div>
      
      </div>
      
      <p className="" id="iyyhre">
        
      </p>
      
      </div>
      
      <div id="ijmh6k" className="">
      
      <p className="seccion-info " id="i6044t">
        <span>Nuestros Beneficios</span>
      </p>
      
      <p className="titulo-seccion " id="iph2u6">
        <span>Que Hacemos BIEN</span>
      </p>
      
      <p className="" id="icukx8">
        
    <div id="" className="page ">
    
    <div id="" className="section ">
    
    <div id="" className="layoutArea ">
    
    <div id="" className="column ">
    
    <div id="ijcwp1" className="">
    
    <div id="i7mwle" className="">
    
      <p className="" id="">
        
      <span className="" id="ixh1ge">
        <span>Elaboramos y hacemos seguimiento al presupuesto
</span>
      </span>
      
      </p>
      
    </div>
    
    <div id="injgbi" className="">
    
      <p className="" id="">
        
      <span className="" id="iatspf">
        <span>Análisis de flujo de caja y optimización de la rentabilidad
</span>
      </span>
      
      </p>
      
    </div>
    
    <div id="ic8kyi" className="">
    
      <p className="" id="">
        
      <span className="" id="ib1ul3">
        <span>Establecemos indicadores de gestión financiera y alertas tempranas
</span>
      </span>
      
      </p>
      
    </div>
    
    <div id="iaxxnp" className="">
    
      <p className="" id="">
        
      <span className="" id="iz8pbu">
        <span>Análisis y modelación de escenarios futuros para la empresa
</span>
      </span>
      
      </p>
      
    </div>
    
    <div id="ij9of5" className="">
    
      <p className="" id="">
        
      <span className="" id="i5wdvw">
        <span>Asesoría en la mitigación de riesgos financieros y como cubrirse frente a
los riesgos que está más expuesto la empresa
</span>
      </span>
      
      </p>
      
    </div>
    
    <div id="iqha5i" className="">
    
      <p className="" id="">
        
      <span className="" id="i0w0rl">
        <span>Acompañamiento en la evaluación financiera de nuevos proyectos
</span>
      </span>
      
      </p>
      
    </div>
    
    <div id="i7mmzg" className="">
    
      <p className="" id="">
        
      <span className="" id="iwdtiz">
        <span>Análisis de los productos financieros pertinentes (costo-beneficio) para </span>
      </span>
      
      <span className="" id="imxdb2">
        <span>un mayor beneficio de la empresa</span>
      </span>
      
      </p>
      
    </div>
    
    <div id="itdtj1" className="">
    
      <p className="" id="">
        
      <span className="" id="i79rvb">
        <span>Análisis de márgenes de venta de la empresa y del sector, y optimizamos
bajo las mejores practicas
</span>
      </span>
      
      </p>
      
    </div>
    
    <div id="ikay7d" className="">
    
      <p className="" id="">
        
      <span className="" id="ig966t">
        <span>Análisis y control de costos
</span>
      </span>
      
      </p>
      
    </div>
    
    </div>
    
    </div>
    
    </div>
    
    </div>
    
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="irqujg" className="">
      
      <div id="iq210w" className="">
      
      <p className="seccion-info centro " id="i3vh0s">
        <span>Información</span>
      <br className="" id="" />
      
      </p>
      
      <p className="titulo-seccion centro " id="icw6p6">
        <span>El Nuevo Rol Del Gerente Financiero</span>
      </p>
      
      </div>
      
      <div id="iwqp1k" className="">
      
      <div
        id="i5mci2"
        className=""
        data-aos="fade-up"
        data-aos-duration="undefined"
      >
      
      <div id="ic1f4l" className="">
      
      <p className="" id="i2tyw5">
        
      <span className="" id="isqcjp">
        <span>La pandemia, la disrupción
tecnológica, y la avalancha de información han
</span>
      </span>
      
      <span className="" id="iqwv5w">
        <span>aumentado la incertidumbre </span>
      </span>
      
      <span className="" id="iy2k1t">
        <span>en el mundo de los
negocios, forzando la </span>
      </span>
      
      <span className="" id="i8prqg">
        <span>transformación de los roles
</span>
      </span>
      
      <span className="" id="iisbf4">
        <span>dentro de las organizaciones.</span>
      </span>
      
      </p>
      
      </div>
      
      <img
        className=""
        id="iqu6j1"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilyoqwd2k.jpg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="i0eta9" className="">
      
      <div
        id="iffvy7"
        className=""
        data-aos="fade-up"
        data-aos-duration="undefined"
      >
      
      <img
        className=""
        id="itzwm6"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilyoqwd2k.jpg"
        alt="undefined"
      />
      
      <div id="ihsxj5" className="">
      
      <p className="" id="io7lhv">
        
      <span className="" id="ite2b1">
        <span>Antes, el CFO se centraba solo en la gestión de
finanzas y el cumplimiento normativo. Ahora, debe
tener una visión más amplia del negocio,
</span>
      </span>
      
      <span className="" id="igkd9u">
        <span>convirtiendo datos en información clave </span>
      </span>
      
      <span className="" id="ies1h6">
        <span>que
permita aterrizarla en un entendimiento a fondo de
la situación de la empresa </span>
      </span>
      
      <span className="" id="ijtbqa">
        <span>conectada </span>
      </span>
      
      <span className="" id="ia8kni">
        <span>a la situación
de mercado y de la coyuntura económica. </span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ihtp5k" className="">
      
      <div
        id="i0pb8w"
        className=""
        data-aos="fade-up"
        data-aos-duration="undefined"
      >
      
      <div id="isfhqx" className="">
      
      <p className="" id="imyixi">
        
    <div id="" className="page ">
    
    <div id="" className="section ">
    
    <div id="" className="layoutArea ">
    
    <div id="" className="column ">
    
      <p className="" id="iphf0g">
        
      </p>
      
    <div id="" className="page ">
    
    <div id="" className="section ">
    
    <div id="" className="layoutArea ">
    
    <div id="" className="column ">
    
      <p className="" id="">
        
      <span className="" id="ir5jo8">
        <span>Esta evolución ha permitido que el CEO tenga
</span>
      </span>
      
      </p>
      
      <p className="" id="">
        
      <span className="" id="ituphg">
        <span>mayor control y tranquilidad sobre la
organización</span>
      </span>
      
      <span className="" id="ioa9bl">
        <span>, generando </span>
      </span>
      
      <span className="" id="ip9unj">
        <span>más confianza </span>
      </span>
      
      <span className="" id="ic0jfz">
        <span>en él y sus
</span>
      </span>
      
      </p>
      
      <p className="" id="">
        
      <span className="" id="i2plfw">
        <span>colaboradores. Con un CFO enfocado en los
impulsores claves, el CEO puede tomar decisiones
pertinentes, informadas y dedicar </span>
      </span>
      
      <span className="" id="ibu1ew">
        <span>más tiempo </span>
      </span>
      
      <span className="" id="iqmz7c">
        <span>a
actividades que </span>
      </span>
      
      <span className="" id="ii19ln">
        <span>generan valor para la empresa. </span>
      </span>
      
      </p>
      
    </div>
    
    </div>
    
    </div>
    
    </div>
    
    </div>
    
    </div>
    
    </div>
    
    </div>
    
      </p>
      
      </div>
      
      <img
        className=""
        id="ir9ytj"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilyor4en3.jpg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="im99zj" className="">
      
      <div
        id="idkv6s"
        className=""
        data-aos="fade-up"
        data-aos-duration="undefined"
      >
      
      <img
        className=""
        id="is9wme"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilyor8zta.jpg"
        alt="undefined"
      />
      
      <div id="iul256" className="">
      
      <p className="" id="i36c7j">
        <span>Asesoría en la mitigación de riesgos financieros y como cubrirse frente a los riesgos que esta mas expuesto la empresa</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="icohi" className="">
      
      <div id="iyghfd" className="">
      
      <div id="i52pnr" className="">
      
      <div id="iys268" className="">
      
      <img
        className=""
        id="imi9j3"
        src="https://assetsprojects.s3.amazonaws.com/404m4lim0h22y66.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="iyo7r6">
        <span>Externaliza tu CFO con TRET</span>
      </p>
      
      <p className="" id="iygu8i">
        
      <a
        target=""
        className=""
        id="ionor8"
        href="mailto:gerencia@tret.com.co"
        type=""
      >
        <span>gerencia@tret.com.co</span>
      </a>
      
      </p>
      
      </div>
      
      <div id="id3akf" className="">
      
      <p className="" id="i160fk">
        <span>Dirección</span>
      </p>
      
      <p className="" id="igdgp1">
        <span>Carrera 8 #85-31, Bogotá, Colombia</span>
      </p>
      
      <p className="" id="io8u0g">
        
      <a
        target=""
        className=""
        id="ivbmuk"
        href="mailto:gerencia@tret.com.co"
        type=""
      >
        <span>gerencia@tret.com.co</span>
      </a>
      
      </p>
      
      </div>
      
      <div id="ipuzkj" className="">
      
      <p className="" id="ifh7p5">
        <span>Contacto</span>
      </p>
      
      <p className="" id="il1rnw">
        
      <a
        target=""
        className=""
        id="ic38zj"
        href="mailto:gerencia@tret.com.co"
        type=""
      >
        <span>gerencia@tret.com.co</span>
      </a>
      
      </p>
      
      </div>
      
      </div>
      
      <div id="iou1pv" className="">
      
      <p className="" id="izq2dp">
        <span>Todos lo derechos reservados</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Planding1xyi8);
  
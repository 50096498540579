
import React from 'react';


import {connect} from 'react-redux';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';


import './luis21.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Pluis21 = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {




  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id64c0853a9255464525641b77" >

      <div id="i55h" className="">
      
      <div id="i9y207" className="">
      
        <a
          target=""
          className=""
          id="in6s8w"
          href="#"
        >
          
        <a
          target=""
          className=""
          id="i0df2k"
          href={`/`}
        >
          
      <img
        className=""
        id="i9xdej"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkr0oo7y.png"
        alt="undefined"
      />
      
        </a>
        
        </a>
        
      <div
        id="i3hvmk"
        className=""
        data-aos="fade-up"
        data-aos-duration="undefined"
      >
      
        <a
          target=""
          className=""
          id="ikstg9"
          href="#"
        >
          
      <p className="" id="itwukm">
        <span>Quiénes Somos</span>
      </p>
      
        </a>
        
        <a
          target=""
          className=""
          id="io3hkg"
          href={`/aprende`}
        >
          
      <p className="" id="iqgcr3">
        <span>Aprende</span>
      </p>
      
        </a>
        
        <a
          target=""
          className=""
          id="iu014u"
          href="/#ijf0dn"
        >
          
      <p className="" id="i0at8i">
        <span>Preguntas  Frecuentes</span>
      </p>
      
        </a>
        
        <a
          target=""
          className=""
          id="i8466w"
          href="/#ijn4q"
        >
          
      <p className="" id="i7nvgl">
        <span>Contáctanos</span>
      </p>
      
        </a>
        
      </div>
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/quienessomos"
        type=""
      >
        <span>Quienes Somos</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/aprende"
        type=""
      >
        <span>Aprende</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/#ijf0dn"
        type=""
      >
        <span>Preguntas Frecuentes</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/#ijn4q"
        type=""
      >
        <span>Contáctanos</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="it53zo" className="">
      
      <p className="" id="idy4ul">
        
      <i className="" id="i9y65i">
        <span>"Nuestra experiencia en el sector bancario revela que el conocimiento financiero y las decisiones de inversión están concentradas en un pequeño grupo de personas. Aunque se han hecho esfuerzos para democratizar este conocimiento, su alcance sigue siendo limitado debido a la alta especialización requerida. Con Treasury Tool, buscamos brindar a más personas acceso a mejores condiciones para sus recursos. Nuestra herramienta facilitará el ingreso seguro al mercado financiero, con el apoyo de expertos, con el fin de maximizar la rentabilidad de nuestros usuarios"</span>
      </i>
      
      </p>
      
      </div>
      
      <div id="ifxad2" className="">
      
      <div id="ijzglu" className="">
      
      <div id="i02zgu" className="">
      
      <p className="" id="i756yj">
        <span>Khristian Madrid</span>
      </p>
      
      <p className="" id="i85fx9">
        <span>Director Ejecutivo</span>
      </p>
      
      </div>
      
      <div id="igha0c" className="">
      
      <img
        className=""
        id="i5l6db"
        src="https://assetsprojects.s3.amazonaws.com/405opfilksfinhv.png"
        alt="undefined"
      />
      
      </div>
      
        <a
          target="_blank"
          className=""
          id="ik1axz"
          href="https://www.linkedin.com/in/khristianmadrid/"
        >
          
      <img
        className=""
        id="itfhdn"
        src="https://assetsprojects.s3.amazonaws.com/405opfilksgiyd3.png"
        alt="undefined"
      />
      
      <p className="" id="ic6j9l">
        
      <b className="" id="i7rfj">
        <span>+ 10 años de experiencia en Finanzas</span>
      </b>
      
      </p>
      
        </a>
        
      <p className="" id="ibmtil">
        <span>Amplia experiencia en la aplicación de productos  financieros en las empresas del sector real, a través del uso de tecnología.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Tuve la oportunidad de administrar y maximizar la rentabilidad de la liquidez en uno de los mayores fondos de infraestructura del país.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      </p>
      
      </div>
      
      <div id="i6qevj" className="">
      
      <div id="i0gntp" className="">
      
      <p className="" id="i3cf8h">
        <span>Luis Murcia</span>
      </p>
      
      <p className="" id="ijgv07">
        <span>Director Producto</span>
      </p>
      
      </div>
      
      <div id="ibexwq" className="">
      
      <img
        className=""
        id="i9snf3"
        src="https://assetsprojects.s3.amazonaws.com/405opfilksfh027.png"
        alt="undefined"
      />
      
      </div>
      
        <a
          target="_blank"
          className=""
          id="i3n0h5"
          href="https://www.linkedin.com/in/Luismurcia1022/"
        >
          
      <img
        className=""
        id="iud3za"
        src="https://assetsprojects.s3.amazonaws.com/405opfilksgiyd3.png"
        alt="undefined"
      />
      
      <p className="" id="ilpxzg">
        
      <b className="" id="ipczqv">
        <span>+ 8 años de experiencia en Banca</span>
      </b>
      
      </p>
      
        </a>
        
      <p className="" id="ie6ulm">
        <span>Economista y analista de datos, con experiencia en el diseño e implementación de modelos para la gestión de inversiones y riesgos financieros.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>A través de mi experiencia me he dado cuenta el valor que tienen los datos para la toma de decisiones, por eso a través de TRET quiero poner al servicio del mayor número de personas este conocimiento.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      <br className="" id="" />
      
      <br className="" id="" />
      
      </p>
      
      </div>
      
      <div id="i50v8w" className="">
      
      <div id="ih2rx9" className="">
      
      <p className="" id="ik7mdw">
        <span>Adip Numa</span>
      </p>
      
      <p className="" id="iiqv1z">
        <span>Director Financiero</span>
      </p>
      
      </div>
      
      <div id="i93zjf" className="">
      
      <img
        className=""
        id="icdyo6"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkubatbw.png"
        alt="undefined"
      />
      
      </div>
      
        <a
          target="_blank"
          className=""
          id="iimp5t"
          href="https://www.linkedin.com/in/adip-numa-hellal-08022312/"
        >
          
      <img
        className=""
        id="itr4rg"
        src="https://assetsprojects.s3.amazonaws.com/405opfilksgiyd3.png"
        alt="undefined"
      />
      
      <p className="" id="iunisf">
        
      <b className="" id="ic84al">
        <span>+ 20 años de experiencia en Banca</span>
      </b>
      
      </p>
      
        </a>
        
      <p className="" id="ipds6f">
        <span>Soy Economista de profesión con más de 20 años de experiencia liderando tesorerías, lo que me ha brindado un profundo conocimiento sobre el manejo de la liquidez y el flujo de caja. A lo largo de mi trayectoria, he negociado diversos productos de los mercados financieros.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>He sido responsable de la implementación exitosa de herramientas tecnológicas para la gestión de tesorería y he llevado a producción nuevos productos del mercado de capitales. Estoy comprometido con el crecimiento y la innovación, siempre buscando oportunidades para mejorar y optimizar las operaciones financieras.</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i5of" className="">
      
      <div id="i8lmjf" className="">
      
      <div id="im4a28" className="">
      
      <img
        className=""
        id="iteqmg"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkr5bykb.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="iqpkke" className="">
      
      <p className="" id="iy8adh">
        <span>Dirección</span>
      </p>
      
      <p className="" id="iiauur">
        <span>Bogotá, Colombia</span>
      </p>
      
      </div>
      
      <div id="imh7h6" className="">
      
      <p className="" id="ijoril">
        <span>Correo de contacto</span>
      </p>
      
      <p className="" id="ik2c1u">
        <span>info@tret.com.co</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iw4n9w" className="">
      
      <div id="ianz3m" className="">
      
      </div>
      
      <div id="iufalf" className="">
      
      <p className="" id="ij1xap">
        <span>Coyright©2023 TRET - Todos los derechos reservados.</span>
      </p>
      
      <div id="ielirh" className="">
      
      <img
        className=""
        id="ii3z3k"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk14hfve.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="irlz65"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk14i6xh.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="it3ooa"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk14iokp.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pluis21);
  

import axios from '../config/axios';

export const loftyCreateUser = ({
  loftyEmail,
  loftyPassword,
  loftyName,
  
  telephone,

}) => new Promise((resolve, reject) => {
  if (  loftyEmail && loftyPassword && loftyName ) {
    axios.post('/api/user/lofty/create/user/email', {
      loftyEmail,
      loftyPassword,
      loftyName,
      
      telephone,

    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error.response?.data));
  } else {
    reject({
      status: 'error',
      info: 'Varios campos son requeridos'
    });
  }
});

export const loftyLoginWithEmail = ({
  loftyEmail, loftyPassword
}) => new Promise((resolve, reject) => {
  if (loftyEmail && loftyPassword) {
    axios.post('/api/user/lofty/login/email', {
      loftyEmail, loftyPassword
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error.response?.data));
  } else if (!loftyEmail) {
    reject({
      status: 'error',
      info: 'Correo es requerido',
    });
  } else if (!loftyPassword) {
    reject({
      status: 'error',
      info: 'Contraseña es requerido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Error varios campos son requeridos',
    });
  }
});

export const loftyGetLoggedUser = ({
  token
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get('/api/user/lofty/one', {
      headers: {
        AuthorizationApp: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const loftyCreateResetToken = ({
  email
}) => new Promise((resolve, reject) => {
  if (email) {
    axios.post('/api/reset/lofty/create', {
      email,
    }).then((res) => {
      const {data} = res;
      if (data.status === 'sucess') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error.response?.data));
  } else {
    reject({
      status: 'error',
      info: 'Correo no definido',
    });
  }
});

export const loftyResetPassword = ({
  token, password, confirmPassword,
}) => new Promise((resolve, reject) => {
  if (token && password && confirmPassword) {
    axios.post(`/api/reset/lofty/password`, {
      token, password, confirmPassword,
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error.response?.data));
  } else if (!token) {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  } else if (!password) {
    reject({
      status: 'error',
      info: 'Contraseña es requerida'
    });
  } else if (!confirmPassword) {
    reject({
      status: 'error',
      info: 'Confirmar contraseña es requerida',
    });
  } else {
    reject({
      status: 'error',
      info: 'Las contraseñas no son iguales'
    });
  }
});

export const loftyChangePassword  = ({
  token, newPassword, confirmNewPassword,
}) => new Promise((resolve, reject) => {
  if (token && newPassword && confirmNewPassword && newPassword === confirmNewPassword) {
    axios.post(`/api/user/lofty/change/password`, {
      newPassword, confirmNewPassword,
    }, {
      headers: {
        AuthorizationApp: token,
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => reject(error.response?.data));
  } else if (!newPassword) {
    reject({
      status: 'error',
      info: 'Nueva contraseña no definido',
    });
  } else if (!confirmNewPassword) {
    reject({
      status: 'error',
      info: 'Confirmar contraseña no definido'
    });
  } else if (newPassword !== confirmNewPassword) {
    reject({
      status: 'error',
      info: 'La contraseña no coinciden',
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const loftyUpdateUserInfo = ({
  token,
  loftyName,
  telephone,

}) => new Promise((resolve, reject) => {
  axios.put(`/api/user/update/info`, {
    loftyName,
    telephone,

  }, {
    headers: {
      AuthorizationApp: token,
    }
  }).then((res) => {
    if (res.data.status === 'success') {
      resolve(res.data);
    } else {
      reject(res.data);
    }
  }).catch((error) => {
    reject(error.response?.data);
  });
});

export const loftyVerifyEmail = ({
  token
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.post(`/api/verify/lofty/account`, {
      token,
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).cath((error) => {
      reject(error.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no esta definido'
    });
  }
});



import axios from '../config/axios';

export const url = '/api/bankstatements'


export const createbankstatements = ({
  token,
	usuario,
	loftyFile,

}) => new Promise((resolve, reject) => {
  if (token && usuario ) {
		const formData = new FormData();
		formData.append('loftyFile', loftyFile);
		formData.append('usuario', usuario);

    axios.post(`${url}//create/basic/6565188e0eebbe3b7e300def`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',

        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else if (!usuario) {
		reject({
			status: 'error',
			info: 'usuario no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});
      


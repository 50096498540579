
import React from 'react';


import {connect} from 'react-redux';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';


import './sobrenosotros24uu4.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Psobrenosotros24uu4 = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {




  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id67116816a68f26b67983cc92" >

      <div id="iw4j" className="">
      
      <div id="ips2" className="">
      
      <div id="itmdc" className="">
      
      <img
        className=""
        id="ismeq"
        src="https://assetsprojects.s3.amazonaws.com/404m4lim23uvzm3.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="iacot" className="">
      
        <LoftyDropdownComponent
          id="iejkse"
          className="gjs-dropdown-menu-container link-navbar "
          childrenShow={
            <>
              <div id="icmp9k" className="gjs-dropdown-menu-trigger ">
<span>Servicios</span></div>

            </>
          }
          childrenHover={
            <>
              <div id="ifwrr4" className="gjs-dropdown-menu-content " style={{display: 'block'}}>

      <a
        target=""
        className="link-navbar "
        id="ixxmu"
        href="/tret"
        type="button"
      >
        
    <div id="ikc1x" className="">
    <span>TRET</span>
    </div>
    
      </a>
      
      <a
        target=""
        className="link-navbar "
        id="i2hsg"
        href="/"
        type="button"
      >
        
    <div id="ij3vk" className="">
    <span>CFO Externo</span>
    </div>
    
      </a>
      </div>

            </>
          }
        />
        
      <a
        target=""
        className="link-navbar "
        id="ibmm3s"
        href="/sobrenosotros"
        type="button"
      >
        
    <div id="iol6vk" className="">
    <span>Sobre Nosotros</span>
    </div>
    
      </a>
      
      <a
        target=""
        className="link-navbar "
        id="i8ax7g"
        href="/blog"
        type="button"
      >
        
    <div id="idaz8h" className="">
    <span>Contenido</span>
    </div>
    
      </a>
      
      </div>
      
      <div id="ipgm41" className="">
      
        <a
          target=""
          className=""
          id="i7mm6p"
          href="#"
        >
          
      <img
        className=""
        id="ip8s2j"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilyoqjlwm.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="ij95fz"
          href="#"
        >
          
      <img
        className=""
        id="ipfg0k"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilyoqmjqh.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="izkwo7"
          href="#"
        >
          
      <img
        className=""
        id="il4qxw"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilyoqn9j4.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <img
        className=""
        id="iok6z3"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkvb1chy.jpg"
        alt="undefined"
      />
      
      <div id="icq3pj" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Home</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>About</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Team</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Contact</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Twitter</span>
      </a>
      
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ihk8" className="">
      
      <div id="it6xg" className="">
      
      <p className="" id="ip3sl">
        <span>Sobre Nosotros</span>
      </p>
      
      <p className="" id="ir8ccl">
        <span>Servicios &gt; Sobre Nosotros</span>
      </p>
      
      <div id="ibin0r" className="">
      
        <a
          target=""
          className="botonprimary "
          id="i1ezla"
          href="#"
        >
          
    <div id="icfw7s" className="">
    <span>Contáctanos</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      <div id="if5pi" className="">
      
      <p className="" id="i803s">
        <span>Con TRET, buscamos brindar a más personas acceso a mejores condiciones para sus recursos. Nuestra herramienta facilitará el ingreso seguro al mercado financiero, con el apoyo de expertos, con el fin de maximizar la rentabilidad de nuestros usuarios</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i6xx" className="">
      
      <div id="i0tfl" className="">
      
      <div id="ivvvb5" className="">
      
      <div id="iyqmu8" className="">
      
      </div>
      
      </div>
      
      <div id="im4sk2" className="">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="isbhos" className="">
      
      <div id="ixm66f" className="">
      
      <div id="ip0maj" className="">
      
      <p className="" id="ienu8f">
        <span>Nuestro Equipo de Expertos</span>
      </p>
      
      <p className="" id="ina5t7">
        <span>Conoce Nuestro Equipo Profesional</span>
      </p>
      
      </div>
      
      <div
        id="irvcbs"
        className=""
        data-aos="fade-down"
        data-aos-duration="2000"
      >
      
      <div id="ik1fro" className="">
      
      <div id="ifwury" className="">
      
      <img
        className=""
        id="i3hxlw"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkj42vcp.jpg"
        alt="undefined"
      />
      
      <div id="ilm4cs" className="">
      
      <p className="" id="iqgasl">
        <span>Khristian Madrid</span>
      </p>
      
      <p className="" id="imr5oa">
        <span>Director Ejecutivo</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="igl9nt" className="">
      
      <img
        className=""
        id="iv5fji"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkj4knra.jpg"
        alt="undefined"
      />
      
      <div id="iqyff9" className="">
      
      <p className="" id="i4w8fi">
        <span>Luis Murcia</span>
      </p>
      
      <p className="" id="iiyyr5">
        <span>Director Producto</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="igfl4q" className="">
      
      <img
        className=""
        id="iuiq1j"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkj4luuc.jpg"
        alt="undefined"
      />
      
      <div id="i1qkbg" className="">
      
      <p className="" id="imtyrx">
        <span>Adip Numa</span>
      </p>
      
      <p className="" id="id44v6">
        <span>Director Financiero</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i8y5ds" className="">
      
      <div id="i1rm39" className="">
      
      <p className="" id="iskk6y">
        <span>Centro de Ayuda</span>
      </p>
      
      <p className="" id="iqw6v3">
        <span>Preguntas Frecuentes</span>
      </p>
      
      </div>
      
      <div
        id="idba1b"
        className=""
        data-aos="fade-up"
        data-aos-duration="undefined"
      >
      
      </div>
      
      <div id="ipr2hz" className="">
      
      <div id="i34wa4" className="">
      
      <div id="i9wi5g" className="">
      
      <div id="ikc2lc" className="">
      
      <p className="subtitulo " id="iw6ito">
        <span>¿Aumentar la rentabilidad implicaría mayores riesgos?</span>
      <br className="" id="" />
      
      </p>
      
      <p className="descripcion2 " id="i2rnbz">
        <span>Nuestras sugerencias se basan en los productos financieros tradicionales ofrecidos por bancos AAA para optimizar la gestión de la liquidez. Esto se fundamenta en la premisa clave de que la liquidez de la empresa debe ser utilizada en su capital de trabajo.</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      <div id="iq08vf" className="">
      
      <img
        className=""
        id="iibjh8"
        src="https://assetsprojects.s3.amazonaws.com/404m4lim2dq7kha.jpg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="icfmzu" className="">
      
      <div id="i8s6av" className="">
      
      <img
        className=""
        id="irn8im"
        src="https://assetsprojects.s3.amazonaws.com/404m4lim2dq9323.jpg"
        alt="undefined"
      />
      
      </div>
      
      <div id="iq80p1" className="">
      
      <p className="subtitulo " id="il3td7">
        <span>¿Para maximizar mi rentabilidad, debo hacer cambio de mis bancos?</span>
      <br className="" id="" />
      
      </p>
      
      <p className="descripcion2 " id="iduxjj">
        <span>No necesariamente. Sin embargo, nuestra experiencia nos ha demostrado que concentrar todas las finanzas en un solo banco puede resultar en ineficiencias en costos financieros, como tasas de créditos y comisiones en pagos a terceros o nómina, así como en la rentabilidad ofrecida en cuentas de ahorro, CDT, entre otros.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Por tanto, diversificar en productos y entidades bancarias termina siendo una estrategia más efectiva para la rentabilidad de la empresa</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      <div id="iufskg" className="">
      
      <div id="i7ls6t" className="">
      
      <p className="subtitulo " id="ih8yyt">
        <span>¿Si no tengo Excedentes de caja o hago uso créditos bancarios puedo optimizar mi flujo de caja?</span>
      <br className="" id="" />
      
      </p>
      
      <p className="descripcion2 " id="iedmvz">
        <span>Las empresas que recurren al crédito para cubrir su faltante de liquidez, a menudo concluyen que no disponen de liquidez para generar una rentabilidad adicional. Sin embargo, tras un análisis inicial, en muchos casos, nos damos cuenta de que existe una liquidez circulante que podría ser optimizar de manera efectiva.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Esto permitiría reducir significativamente los costos financieros,y generar mayores ingresos para la empresa</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      </p>
      
      </div>
      
      <div id="i4gp8v" className="">
      
      <img
        className=""
        id="ik0t72"
        src="https://assetsprojects.s3.amazonaws.com/404m4lim2dqa1nt.jpg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="i6riff" className="">
      
      <div id="iw9hzs" className="">
      
      <img
        className=""
        id="isjw1g"
        src="https://assetsprojects.s3.amazonaws.com/404m4lim2dqbtyj.jpg"
        alt="undefined"
      />
      
      </div>
      
      <div id="iulp8t" className="">
      
      <p className="subtitulo " id="i07qxl">
        <span>¿TRET es una empresa que se dedica a administrar recursos?</span>
      <br className="" id="" />
      
      </p>
      
      <p className="descripcion2 " id="ijncn7">
        <span>TRET es una empresa que actúa como aliado estratégico para otras compañías, brindando información financiera relevante y valiosa. Nuestra misión es empoderarte para que puedas tomar las mejores decisiones para tu empresa sin perder autonomía en la administración de tus recursos. </span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Nos enfocamos en proporcionarte datos precisos y análisis detallados para que puedas optimizar tus operaciones y alcanzar tus objetivos financieros con confianza. Nuestra colaboración te permitirá tomar decisiones informadas y estratégicas para el crecimiento y éxito de tu negocio.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      <p className="" id="">
        
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="icohi" className="">
      
      <div id="iyghfd" className="">
      
      <div id="i52pnr" className="">
      
      <div id="iys268" className="">
      
      <img
        className=""
        id="imi9j3"
        src="https://assetsprojects.s3.amazonaws.com/404m4lim0h22y66.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="iyo7r6">
        <span>Externaliza tu CFO con TRET</span>
      </p>
      
      <p className="" id="iygu8i">
        
      <a
        target=""
        className=""
        id="ionor8"
        href="mailto:gerencia@tret.com.co"
        type=""
      >
        <span>gerencia@tret.com.co</span>
      </a>
      
      </p>
      
      </div>
      
      <div id="id3akf" className="">
      
      <p className="" id="i160fk">
        <span>Dirección</span>
      </p>
      
      <p className="" id="igdgp1">
        <span>Carrera 8 #85-31, Bogotá, Colombia</span>
      </p>
      
      <p className="" id="io8u0g">
        
      <a
        target=""
        className=""
        id="ivbmuk"
        href="mailto:gerencia@tret.com.co"
        type=""
      >
        <span>gerencia@tret.com.co</span>
      </a>
      
      </p>
      
      </div>
      
      <div id="ipuzkj" className="">
      
      <p className="" id="ifh7p5">
        <span>Contacto</span>
      </p>
      
      <p className="" id="il1rnw">
        
      <a
        target=""
        className=""
        id="ic38zj"
        href="mailto:gerencia@tret.com.co"
        type=""
      >
        <span>gerencia@tret.com.co</span>
      </a>
      
      </p>
      
      </div>
      
      </div>
      
      <div id="iou1pv" className="">
      
      <p className="" id="izq2dp">
        <span>Todos lo derechos reservados</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Psobrenosotros24uu4);
  

import React, {useEffect, useState} from 'react';


import {connect} from 'react-redux';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {createbankstatements } from '../../api/bankstatements.api'

import './dashboardinicial.scss';






const Pdashboardinicial = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


useEffect(() => {
		const loftyHandleData = async () => {
		}
		loftyHandleData();
	}, []);


  
  
  
  

  if (!loftytoken) {
		return (<div>Requiere Autenticación</div>);
	}

  return (
    <div id="id65452847c239522da2e3aeed" >

      <div id="fgfg" className="">
      
      <div id="iifg" className="gjs-row ">
      
      <div id="ig49" className="gjs-cell ">
      
      <div id="i99szh" className="">
      
        <a
          target=""
          className=""
          id="i9ymbj"
          href={`/dashboard`}
        >
          
      <img
        className=""
        id="i1d1f1"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkr0oo7y.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="iyxmw" className="">
      
        <a
          target=""
          className=""
          id="ishz2"
          href={`/dashboard`}
        >
          
      <div id="i0uip" className="itemnavigationdefault ">
      
      <p className="" id="ij9fr">
        <span>Inicio</span>
      </p>
      
      </div>
      
        <a
          target=""
          className=""
          id="isgtq"
          href={`/dashboard/productos`}
        >
          
      <div id="i51wa" className="itemnavigation ">
      
      <p className="" id="ib3yz">
        <span>Mis Productos</span>
      </p>
      
      </div>
      
        </a>
        
        </a>
        
        <a
          target=""
          className=""
          id="isg1w"
          href="#"
        >
          
      <div id="ihupb" className="itemnavigation ">
      
      <p className="" id="irvrr">
        <span>Configuraciones</span>
      </p>
      
      </div>
      
        </a>
        
      </div>
      
      <div id="igr3j" className="">
      
      <button
        type=""
        name=""
        id="i0k7v"
        className="buttoniniciosesion "
        onClick={async () => {
		deleteLoftytokenApp();
		window.location.href = '/signin';
}}

      >
      
      <p className="" id="isj6f">
        <span>
          Cerrar Sesion
        </span>
      </p>
      
      </button>
      
      </div>
      
      </div>
      
      <div id="io5ng" className="gjs-cell ">
      
      <div id="ipm2s" className="">
      
      <p className="" id="if76p">
        <span>Inicio</span>
      </p>
      
      </div>
      
      <div id="i2c23" className="">
      
      <div id="iy6mf" className="">
      
      <p className="" id="it73y">
        <span>¡Bienvenido! Estamos aquí para ayudarte a gestionar tu flujo de caja de manera eficiente. Si tienes alguna pregunta, no dudes en contactarnos. ¡Tu éxito financiero es nuestra prioridad!</span>
      </p>
      
        <div className="" id="is8bw">
          
      <form
        id="imk9s"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					for (const loftyFile of e.target.fileBucket.files) {
				createbankstatements({
							token: loftyuser?._id,
					usuario: loftyuser?._id,
				});
					}			alert("Se agrego de manera exitosa");
		} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="ibtkj" className="">
      
      <br className="" id="" />
      
      <p className="" id="i4coh">
        <span>Sube tus extractos bancarios aquí y recibirás notificaciones por WhatsApp y correo sobre las novedades de tu cuenta.</span>
      </p>
      
      <input
        name="fileBucket"
        type="file"
        placeholder=""
        className=""
        id="itedt"
      />
      
      </div>
      
      <button
        type="submit"
        name=""
        id="ir2ls"
        className="buttoniniciosesion "
        
      >
      
      <div id="iuups" className="">
      <span>Enviar</span>
      </div>
      
      </button>
      
      </form>
      
        </div>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pdashboardinicial);
  
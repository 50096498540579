
import React, {useEffect, useState} from 'react';


import {connect} from 'react-redux';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {createcontact } from '../../api/contact.api'

import './inicio.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Pinicio = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


useEffect(() => {
		const loftyHandleData = async () => {
		}
		loftyHandleData();
	}, []);


  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id64afda918b8ac2e1bb827064" >

      <div id="i55h" className="">
      
      <div id="icv7c" className="">
      
      <div id="iigln" className="">
      
      <img
        className=""
        id="ih3ya"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk11rnk9.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="ik5bn"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk11skmt.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="ivz5n" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Home</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>About</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Team</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Contact</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Twitter</span>
      </a>
      
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <img
        className=""
        id="icm2ph"
        src="https://assetsprojects.s3.amazonaws.com/405gznilk1i8ejy.png"
        alt="undefined"
      />
      
      <div id="ieynp" className="gjs-row ">
      
      <div id="ipzu4" className="gjs-cell ">
      
      <p className="" id="idt3r">
        <span>Somos</span>
      <br className="" id="" />
      
      <b className="" id="">
        <span>TU TESORERÍA</span>
      <br className="" id="" />
      
      </b>
      <span>del FUTURO</span>
      </p>
      
      <div id="io1sl" className="">
      
      </div>
      
      <div id="if42j" className="">
      
        <a
          target=""
          className=""
          id="i6bns"
          href="#"
        >
          
    <div id="iz9oi" className="">
    <span>COTIZA EN TIEMPO REAL</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      <div id="iwhzj" className="gjs-cell ">
      
      <img
        className=""
        id="i7t64"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk123i4p.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ie7i" className="">
      
      <div id="ikgn" className="">
      
      <div id="iju15" className="">
      
      <div id="irx7i" className="">
      
      <div id="ii5v3" className="">
      
      <img
        className=""
        id="i5xhw"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk12bkfl.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <p className="" id="itjm8">
        <span>Gestión Inteligente</span>
      </p>
      
      <p className="" id="ip277">
        <span>Logra tener el control de tu liquidez sin puntos ciegos y con data de tu comportamiento, y el del mercado financiero, fácil de analizar</span>
      </p>
      
      </div>
      
      <div id="ivvrqh" className="">
      
      <div id="im7f4" className="">
      
      <div id="if84z" className="">
      
      <img
        className=""
        id="iksr9"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk12fyml.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <p className="" id="icetg">
        <span>Aprende mientras haces</span>
      </p>
      
      <p className="" id="ibb26r">
        <span>Sabemos que la mejor</span>
      <br className="" id="" />
      <span>forma de aprender es con</span>
      <br className="" id="" />
      <span>acompañamiento y </span>
      <br className="" id="" />
      <span>ejecución simultanea</span>
      </p>
      
      </div>
      
      <div id="i9ysw" className="">
      
      <div id="icrpu" className="">
      
      <div id="i1enk" className="">
      
      <img
        className=""
        id="iw6jk"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk12g8l1.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <p className="" id="iei1l">
        <span>Soporte 24/7</span>
      </p>
      
      <p className="" id="i28q8">
        <span>Logra tener el control de tu liquidez sin puntos ciegos y con data de tu comportamiento, y el del mercado financiero, fácil de analizar</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="icm6cf" className="gjs-row ">
      
      <div id="i3kv92" className="gjs-cell ">
      
      </div>
      
      <div id="i3x0ad" className="gjs-cell ">
      
      <p className="" id="io8thq">
        <span>¿Sabías que los recursos de tu empresa pueden estar perdiendo valor en este momento?</span>
      </p>
      
      <p className="" id="ixcyot">
        <span>Conoce el potencial que tiene</span>
      <br className="" id="" />
      <span>los recursos de tu empresa</span>
      </p>
      
        <a
          target=""
          className=""
          id="i9pm15"
          href="#"
        >
          
    <div id="i0rb6y" className="">
    <span>COTIZA EN TIEMPO REAL</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      <div id="ih3j" className="">
      
      <div id="ilb3e4" className="gjs-row ">
      
      <div id="igpwko" className="gjs-cell ">
      
      <p className="" id="iilxvb">
        <span>¿Administras tus recursos únicamente en </span>
      <br className="" id="" />
      <span>instrumentos a la vista? </span>
      </p>
      
      <p className="" id="i6altf">
        <span>Conoce el potencial que tiene</span>
      <br className="" id="" />
      <span>los recursos de tu empresa</span>
      </p>
      
      <div id="i79kaf" className="">
      
        <a
          target=""
          className=""
          id="i4rkbi"
          href="#"
        >
          
    <div id="isogzr" className="">
    <span>Si únicamente a la vista</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="imbntg"
          href="#"
        >
          
    <div id="i0ofd3" className="">
    <span>También en CTDs</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      <div id="ig18uk" className="gjs-cell ">
      
      <div id="ib3qne" className="">
      
      <img
        className=""
        id="izjrpi"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk12zwiu.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i8x7l" className="">
      
      <div id="ihylc8" className="">
      
      <p className="" id="iwhbej">
        <span>¿Cuál es el valor de los recursos</span>
      <br className="" id="" />
      <span>que tienes invertidos?</span>
      </p>
      
      <div id="ioaqgy" className="">
      
        <div id="undefined" className="inputContainer ">
          
      <input
        name=""
        type="text"
        placeholder=""
        className="formInput "
        id=""
      />
      
        </div>
      
      <p className="" id="i8uyxd">
        <span>Millones</span>
      </p>
      
        <a
          target=""
          className=""
          id="i7lpq4"
          href="#"
        >
          
    <div id="i1ii5h" className="">
    <span>SIMULAR</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      <div id="iyaic" className="">
      
      <div id="i68hp6" className="gjs-row ">
      
      <div id="iav115" className="gjs-cell ">
      
      <img
        className=""
        id="i84n26"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk13cngw.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="i4mxy7" className="gjs-cell ">
      
      <p className="" id="ioh5ti">
        <span>Ganancia Total</span>
      </p>
      
      <div id="ibg3yq" className="">
      
      <img
        className=""
        id="iowgsn"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk13eg5p.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="iu4pxb" className="">
      
      <img
        className=""
        id="ial8cf"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk13f0s8.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="i94g6d" className="">
      
      <img
        className=""
        id="iub0y4"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk13eg5p.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ijn4q" className="">
      
      <p className="" id="io4ip2">
        <span>¿Quieres conocer cómo puedes conseguir que tus</span>
      <br className="" id="" />
      <span>recursos lleguen al máximo de su potencial? </span>
      </p>
      
        <div className="" id="icbglp">
          
      <form
        id="is002f"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					createcontact({
						nombre: e.target.nombre.value,
						empresa: e.target.empresa.value,
						telefono: e.target.telefono.value,
						correo: e.target.correo.value,
					});
					alert("Se envió exitosamente la información");
		} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="ij9nul" className="">
      
      <label id="ihdlid" className="">
      <span>Nombre:</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="nombre"
        type="text"
        placeholder=""
        className=""
        id="inzr6s"
      />
      
      </div>
      
      <div id="i62lny" className="">
      
      <label id="imp4u3" className="">
      <span>Empresa:</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="empresa"
        type="text"
        placeholder=""
        className=""
        id="iiic3j"
      />
      
      </div>
      
      <div id="im6ilb" className="">
      
      <label id="ijj15h" className="">
      <span>Teléfono:</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="telefono"
        type="text"
        placeholder=""
        className=""
        id="ihkf52"
      />
      
      </div>
      
      <div id="irc4ec" className="">
      
      <label id="idzwl7" className="">
      <span>E-Mail:</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="correo"
        type="text"
        placeholder=""
        className=""
        id="i120ad"
      />
      
      </div>
      
      <div id="iqdav3" className="">
      
      <button
        type="submit"
        name=""
        id="i47zps"
        className=""
        
      >
      <span>Enviar</span>
      </button>
      
      </div>
      
      </form>
      
        </div>
        
      <div id="io0gfm" className="">
      
      <img
        className=""
        id="i0uegp"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk144czy.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="i5of" className="">
      
      <div id="i8lmjf" className="">
      
      <div id="im4a28" className="">
      
      <img
        className=""
        id="iteqmg"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk11rnk9.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="iqpkke" className="">
      
      <p className="" id="iy8adh">
        <span>Dirección</span>
      </p>
      
      <p className="" id="iiauur">
        <span>Ciudad de Bogota, Colombia</span>
      </p>
      
      </div>
      
      <div id="imh7h6" className="">
      
      <p className="" id="ijoril">
        <span>Correo de contacto</span>
      </p>
      
      <p className="" id="ik2c1u">
        <span>info@reasurytools.com.co</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iw4n9w" className="">
      
      <div id="ianz3m" className="">
      
      </div>
      
      <div id="iufalf" className="">
      
      <p className="" id="ij1xap">
        <span>Coyright©2023 Treasury Tool - Todos los derechos reservados.</span>
      </p>
      
      <div id="ielirh" className="">
      
      <img
        className=""
        id="ii3z3k"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk14hfve.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="i1414e"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk14i6xh.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="it3ooa"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk14iokp.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pinicio);
  